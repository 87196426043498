/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Namecards = (function () {

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        $('#namecards').find('header').remove();
        if ('ncImage' in AppData.settings) {
            let fg = AppData.medias.find(function (item){
                return item.id == AppData.settings.ncImage;
            });
            if (fg && fg.files[localState.lang].file != undefined) {
                $("#namecards").find(".namecardscontainer").attr('style', 'background-image: url(\'' + fg.files[localState.lang].file + '\')');
            }
        }
        $("#namecards").find(".namecardscontainer .namecardstitle").html(AppData.profile.name ? AppData.profile.name[AppData.settings.lang] : '');
        $("#namecards").find(".namecardscontainer .namecardsubstitle").html(AppData.profile.slogan ? AppData.profile.slogan[AppData.settings.lang] : '');
    }

    function showDetails() {
        Init();
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        showDetails: showDetails
    }
})();

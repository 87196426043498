/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Messageboard = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */

    var container;
    var messages_container;
    let currentMessages = [];
    var tpl;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init(id) {

        container = $("#messageboard");
        messages_container = $(".messages", container);
        tpl = $("#tplMsgItem", container).contents();

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'getMessageboards'
            },
            success: function (response) {

                if (id == undefined || id == "") {
                    let msg = response.find(function (item) {
                        return item.automatic;
                    });

                    let bc;

                    if (localState.broadcast) {
                        bc = AppData.broadcast.find(function (item) {
                            return item.id == localState.broadcast.itemIndex;
                        });
                    }

                    if (bc) {
                        msg = response.find(function (item) {
                            return item.broadcast == bc.id;
                        });
                    }

                    if (msg != undefined) {
                        id = msg.id;
                    }
                }

                $.ajax({
                    method: 'post',
                    url: 'api/',
                    data: {
                        do: 'getMessageboardDisplay',
                        id: id || 0
                    },
                    success: function (res) {
                        AppData.messageboard = res;
                        messages_container.empty();
                        renderDetails();
                    }
                });
            }
        });
    }

    function renderDetails() {
        var
            $header = $('#messageboard .data-label'),
            $msg_cont = $('#messageboard .message_container'),
            $no_result = $('#messageboard .no-result'),
            $title = $('#messageboard .data-title'),
            $details = $('#messageboard article');
            /*data = AppData.messageboard.find(function (el) {
                return el.visible === true;
            }) || undefined;*/

        var data = AppData.messageboard;

        if (data.length > 0) {
            $('#messageboard [name="messageboard"]').val(data[0].id);
            $header.text(data[0].name[localState.lang]);
            $title.text(data[0].name[localState.lang]);
            //$details.find('textarea').val('');

            if (data[0].text_allowed) {
                $(".texfield").show();
                $(".submit_text").show();
            } else {
                $(".texfield").hide();
                $(".submit_text").hide();
            }

            if (data[0].pic_allowed) {
                $(".send_image").show();
            } else {
                $(".send_image").hide();
            }

            let actual_msg = $('#messageboard [name="messageboard"]').val();
            let msgboard = data.find(function (item){
                return item.id == actual_msg;
            });

            if (msgboard) {
                msgboard.messages.sort(function (a, b){
                    let date1 = Date.parse(a.created_at);
                    let date2 = Date.parse(b.created_at);

                    return date1 - date2;
                });

                //container.find('header .data-label').html(data[i].name[localState.lang]);
                for (var m in msgboard.messages) {
                    var msg = msgboard.messages[m];

                    //if (currentMessages.indexOf(msg.id) === -1) {
                        var clone = tpl.clone();
                        //currentMessages.push(msg.id);
                        clone.find(".data-sender").html(msg.username[localState.lang]);
                        if (msg.filename) {
                            clone.find(".data-message").html('<div class="image_msg"><img src="'+msg.filename+'"></div>');
                        } else {
                            clone.find(".data-message").html(msg.message);
                        }
                        clone.appendTo(messages_container);
                    //}
                }
            }

        } else {
            console.log("empty");
            messages_container.empty();
            currentMessages = [];
        }

        showMsgBoard();
        $msg_cont.toggle(data.length > 0);
        $no_result.toggle(data.length == 0);

        $("#messageboard .messages_container").animate({scrollTop: $("#messageboard .messages_container").prop("scrollHeight")}, 400);
    }

    function showMsgBoard() {
        if (AppData.modulsettings.msgboard_showonclient) {
            messages_container.show();
        } else {
            messages_container.hide();
        }

        if (AppData.modulsettings.transparent_messageboard) {
            $("#messageboard .container").addClass("transparent");
        } else {
            $("#messageboard .container").removeClass("transparent");
        }
    }

    function evtSubmitForm(e) {
        e.preventDefault();
        var
            $self = $(this),
            formData = $self.serializeObject();
        $.post('api/', {
            do: 'saveMessageboardMessage',
            data: formData
        }, function (res) {
            $('#messageboard article').find('textarea').val('');
            //Layout.hide('messageboard');
            Socket.Send('Messageboard.AppendMessage', res, {
                client: ['messageboard', 'client']
            });

            AppendMessage(res);

            //Modal.resultconfirmation({title: Language.getItem("messageboard-success"), text: "", canClose: true});
        });
    }

    function AppendMessage(message) {

        //if (message.messageboard === currentQuestion) {
            //if (currentMessages.indexOf(message.id) === -1) {
                var clone = tpl.clone();
                //currentMessages.push(message.id);
                //clone.find(".data-sender").html(message.username[localState.lang]);
                clone.find(".data-sender").html(message.username[localState.lang]);
                if (message.filename) {
                    clone.find(".data-message").html('<div class="image_msg"><img src="'+message.filename+'"></div>');
                } else {
                    clone.find(".data-message").html(message.message);
                }
                clone.appendTo(messages_container);
            //}
        //}

        $("#messageboard .messages_container").animate({scrollTop: $("#messageboard .messages_container").prop("scrollHeight")}, 400);
    }

    function sendImage() {
        if (AppData.photos.length === 0) {
            $(".from_gallery").hide();
        } else {
            $(".from_gallery").show();
        }

        $(".messageboard_modal").fadeIn(300);
    }

    function closeModal() {
        var modal = $(".messageboard_modal");

        modal.fadeOut(300, function () {
            $(".buttons", modal).show();
            $(".gallery", modal).hide();
        });
    }

    function openGallery() {

        var gallery = $(".gallery");

        gallery.empty();

        AppData.photos.forEach(function (item) {
            gallery.append('<div class="galimage" data-id="'+item.id+'"><img src="upload/user_photos/'+item.filename+'"></div>');
        });

        var modal = $(".messageboard_modal");
        $(".buttons", modal).hide();
        $(".gallery", modal).show();
    }

    function newPhoto() {
        Kiosk.camera(sendPhotoToMessageBoard);
    }

    function sendPhotoToMessageBoard(photo_base64) {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'uploadPhoto',
                image: photo_base64,
                returnid: true
            },
            success: function (response) {

                Photos.buildFromSource();

                var id = response;

                photoToMsg(id);
            }
        });
    }

    function selectPhoto() {
        var id = $(this).attr("data-id");
        photoToMsg(id);
    }

    function photoToMsg(id) {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'saveMessageboardMessage',
                data: {
                    messageboard: $('[name="messageboard"]').val(),
                    message: '',
                    imageid: id
                }
            },
            success: function (resp) {

                Socket.Send('Messageboard.AppendMessage', resp, {
                    client: 'messageboard'
                });

                Modal.resultconfirmation({
                    title: Language.getItem('upload-success'),
                    text: '',
                    canClose: true
                }, function () {
                    closeModal();
                });
            }
        });
    }

    function showDetails(id) {
        if (id) {
            /*if (localState.messageboard != undefined) {
                if (id !== localState.messageboard.itemIndex || localState.messageboard.itemIndex === 0) {
                    localState.messageboard.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.messageboard = { 'itemIndex': String(id)};
            }*/
            Layout.show('messageboard');
            Init(id);

            $("#messageboard .messages_container").animate({scrollTop: $("#messageboard .messages_container").prop("scrollHeight")}, 400);
        }
    }

    function shown() {
        Init();
        $("#messageboard .messages_container").animate({scrollTop: $("#messageboard .messages_container").prop("scrollHeight")}, 400);
    }

    $(document)
        .on('submit', '#messageboard form', evtSubmitForm)
        .on('click', '#messageboard .send_image', sendImage)
        .on('click', '#messageboard .galimage', selectPhoto)
        .on('click', '#messageboard .messageboard_modal .close', closeModal)
        .on('click', '#messageboard .messageboard_modal .from_gallery', openGallery)
        .on('click', '#messageboard .messageboard_modal .new_photo', newPhoto);
    return {
        Init: Init,
        showDetails:showDetails,
        AppendMessage:AppendMessage,
        currentMessages:currentMessages,
        showMsgBoard: showMsgBoard,
        shown: shown
    }
})();

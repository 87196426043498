/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Notes = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateNotesItem;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateNotesItem = $('template#tplNotesItem').remove().contents();
        renderList();
        renderDetails();
        delete Notes.Init;
    }

    /* ----- Build Docs List ---------------------------------------------------------------------------------------- */
    function renderList() {
        var
            $self = $('#notes .notesList'),
            $fragmentNotes = $(document.createDocumentFragment());
        $.each(AppData.notes, function (i, el) {
            var $clone = $templateNotesItem.clone();
            $clone.attr('data-index', el.id);
            $clone.find('.data-title').text(el.title);
            $clone.appendTo($fragmentNotes);
        });
        $self.html($fragmentNotes);
        $('#notes').find('.no-result').toggle(Boolean(!AppData.notes.length));
    }

    function renderDetails() {
        if ('notes' in localState) {
            var
                $header = $('#notes-details .data-label'),
                $details = $('#notes-details article'),
                data = AppData.notes.find(function (el) {
                    return el.id === localState.notes.itemIndex
                }) || [];
            $header.text(data.title || Language.getItem('notes-new'));
            $details.find('input, textarea').val('');
            $details.find('.delete').toggleClass('hidden', !data.id);
            $.each(data, function (i, el) {
                $details.find('[name=' + i + ']').val(el || '');
            });
            if ('input' in localState.notes) {
                $.each(localState.notes.input, function (name, value) {
                    $details.find('[name="' + name + '"]').val(value);
                });
            }
        }
    }

    function evtClickItem(e) {
        localState.notes = {
            itemIndex: String($(this).data('index')) || 0
        };
        renderDetails();
        if (e.data && e.data.title) {
            $('#notes-details [name="title"]').val(e.data.title);
        }
        Layout.show('notes-details');
    }

    function evtSubmitForm(e) {
        e.preventDefault();
        var
            $self = $(this),
            formData = $self.serializeObject();
        $self.addClass('disabled');
        writeDbLog("note saved", "", "user_logs", "note");
        $.post('api/', {
            do: 'saveNote',
            data: formData
        }, function (res) {
            AppData.notes = res;
            renderList();
            if(AppData.settings.homeScreen === "floating") {
                $("#" + localState.layout[localState.layout.length - 2]).show();
                $("#" + localState.layout[localState.layout.length - 2]).attr("style", "background-color: transparent");
            }
            Layout.hide('notes-details');
            $self.removeClass('disabled');
        });
    }

    function evtResetForm(e) {
        e.preventDefault();
        var
            $self = $(this),
            id = $(this).closest('form').find('[name=id]').val();
        Modal.confirm(Language.getItem('notes-confirm'), function () {
            $.post('api/', {
                do: 'removeNote',
                data: id
            }, function (res) {
                AppData.notes = res;
                renderList();
                Layout.hide('notes-details');
                $self.removeClass('disabled');
            });
        });
    }

    function evtInputBackup() {
        var $this = $(this);
        if ($this.get(0).hasAttribute('name')) {
            localState.notes.input = localState.notes.input || {};
            localState.notes.input[$this.attr('name')] = $this.val();
        }
    }

    function showDetails(id) {
        if (id) {
            if (localState.notes != undefined) {
                if (id !== localState.notes.itemIndex || localState.notes.itemIndex === 0) {
                    localState.notes.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.notes = { 'itemIndex': String(id)};
            }
            Layout.show('notes-details');
        }
    }

    $(document)
        .on('click', '#notes .item', evtClickItem)
        .on('click', '#notes .new', evtClickItem)
        .on('click', '#notes-details form .delete', evtResetForm)
        .on('input', '#notes-details input, #notes-details textarea', evtInputBackup)
        .on('submit', '#notes-details form', evtSubmitForm);
    return {
        Init: Init,
        showDetails:showDetails,
        evtClickItem: evtClickItem
    }
})();

/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
var Photos = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templatePhotosItem;
    var $templateGalleryItem;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templatePhotosItem = $('template#tplPhotosItem').remove().contents();
        $templateGalleryItem = $('template#gallery_list_item').remove().contents();
        renderList();
        gallerySelect();
        Kiosk.addCameraListener('camera', uploadPhoto);
        $("#photos #photo_upload").on('change', uploadPhotoFile);

        delete Photos.Init;
    }

    /* ----- Build Docs List ---------------------------------------------------------------------------------------- */
    function renderList() {
        var
            $self = $('#photos .photosList'),
            $fragmentphotos = $(document.createDocumentFragment());
        $.each(AppData.photos, function (i, el) {
            var $clone = $templatePhotosItem.clone();
            $clone.attr('data-index', el.id);
            $clone.attr('data-photo_path', 'upload/user_photos/'+el.filename);
            $clone.find('.user_photo').css({
                "background-image": "url('upload/user_photos/"+el.filename+"')"
            });

            if (AppData.modulsettings.gallery_marktogallery) {
                $clone.find(".add_to_gallery").removeClass("hidden");
            } else {
                $clone.find(".add_to_gallery").addClass("hidden");
            }

            //$clone.find('.add_to_gallery span').html(Language.getItem('photos-choose-gallery'));
            $clone.appendTo($fragmentphotos);
        });
        $self.html($fragmentphotos);
        $('#photos').find('.no-result').toggle(Boolean(!AppData.photos.length));

        if (AppData.modulsettings.gallery_marktosend) {
            $("#photos .send_bulk").show();
        } else {
            $("#photos .send_bulk").hide();
        }

    }

    function base64File(file, callback) {
        var FR= new FileReader();

        FR.addEventListener("load", function(e) {
            callback(e.target.result);
        });

        FR.readAsDataURL( file );
    }

    function evtClickItem(e) {
        if (Kiosk.kiosk_supported()) {
            Kiosk.camera();
        } else {
            $("#photos #photo_upload").trigger("click");
        }
    }

    function uploadPhotoFile() {

        $("#photos .upload_progress .progress-bar").css("width", "0");
        $("#photos .upload_progress .status").text("0%");
        $("#photos .upload_progress").show();

        var files = base64File($('#photo_upload')[0].files[0], function (b64image) {

            var b64 = b64image.split(",");

            var fd = new FormData();
            fd.append('image', b64[1]);
            fd.append('do', 'uploadPhoto');

            $.ajax({
                method: 'post',
                url: 'api/',
                data: fd,
                contentType: false,
                dataType: 'json',
                cache: false,
                processData:false,
                xhr: function(){
                    //upload Progress
                    var xhr = $.ajaxSettings.xhr();
                    if (xhr.upload) {
                        xhr.upload.addEventListener('progress', function(event) {
                            var percent = 0;
                            var position = event.loaded || event.position;
                            var total = event.total;
                            if (event.lengthComputable) {
                                percent = Math.ceil(position / total * 100);
                            }
                            //update progressbar
                            $("#photos .upload_progress .progress-bar").css("width", + percent +"%");
                            $("#photos .upload_progress .status").text(percent +"%");
                        }, true);
                    }
                    return xhr;
                },
                mimeType:"multipart/form-data"
            }).done(function(res){
                console.log(res);
                AppData.photos = res;
                renderList();
                //$("#photos .upload_progress .progress-bar").css("width", "0%");
                //$("#photos .upload_progress .status").text("0%");
                $("#photos .upload_progress").hide();
            });
        });
    }

    function uploadPhoto(photo_base64) {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'uploadPhoto',
                image: photo_base64
            },
            success: function (response) {
                AppData.photos = response;
                renderList();
            }
        });
    }

    function viewImage() {
        var parent = $(this).closest($(".photoitem"));

        var imgviewer = $('<div/>').addClass("imageviewer");
        var bigimg = $('<img/>').attr("src", parent.attr("data-photo_path"));

        imgviewer.on("mouseup touchend", function (e) {
            e.preventDefault();
            imgviewer.fadeOut(400, function () {
                imgviewer.remove();
            });
        });

        imgviewer.append(bigimg);
        imgviewer.appendTo($("body"));
        imgviewer.fadeIn(400);
    }

    function selectImage() {
        var parent = $(this).closest($(".photoitem"));
        parent.toggleClass("selected");

        if ($(".selected", $("#photos")).length > 0) {
            $("#photos .actions").removeClass("disabled");
        } else {
            $("#photos .actions").addClass("disabled");
        }
    }

    function deleteImage() {
        var _this = $(this);

        Modal.confirm(Language.getItem("photos-confirm-delete"), function () {
            var parent = _this.closest($(".photoitem"));
            var imageid = parent.attr("data-index");
            sendDelete([imageid]);
        });
    }

    function deleteBulk() {
        Modal.confirm(Language.getItem("photos-confirm-delete"), function () {
            var imageids = [];
            $("#photos .photosList .photoitem.selected").each(function () {
                imageids.push($(this).attr("data-index"));
            });
            sendDelete(imageids);
        });
    }

    function sendBulk() {

        if (AppData.profile.email === "") {
            Modal.alert("Kérjük adja meg az email címét a profiljában.", function(){
                Layout.show("profile");
            });
        } else {
            Modal.confirm("Biztosan elküldi a fotókat?", function () {
                var imageids = [];
                $("#photos .photosList .photoitem.selected").each(function () {
                    imageids.push($(this).attr("data-index"));
                });
                $.ajax({
                    method: 'post',
                    url: 'api/',
                    data: {
                        do: 'markPhotosToSend',
                        id: imageids
                    },
                    success: function (response) {
                        AppData.photos = response;
                        renderList();
                        Modal.resultconfirmation({
                            title: "Sikeres fotóküldés.",
                            text: '',
                            canClose: true
                        }, function () {
                            $('#modal').hide();
                        });
                    }
                });
            });
        }
    }

    function sendDelete(imageids) {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'removePhoto',
                id: imageids
            },
            success: function (response) {
                AppData.photos = response;
                renderList();
            }
        });
    }

    function buildFromSource() {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'getPhotos'
            },
            success: function (resp) {
                AppData.photos = resp;
                renderList();
            }
        });
    }

    function gallerySelect() {
        var gs = $("#photos .gallery_list .items");
        gs.html("");
        $.each(AppData.gallery, function (i, item) {
            var $clone = $templateGalleryItem.clone();
            $clone.attr('data-index', item.id);
            $clone.find(".gallery_title").html(item.title[localState.lang]);
            gs.append($clone);
        });
    }

    function openGalleryList() {
        var photoid = $(this).closest(".photoitem").attr("data-index");

        $("#photos .gallery_list .gallery_item").each(function (i, el) {
            $(this).removeClass("added");

            var galid = $(this).attr("data-index");
            var gallery_item = AppData.gallery.find(function (g_el) {
                return g_el.id == galid;
            });

            if (gallery_item) {
                var in_gallery = gallery_item.images.find(function(image) {
                    return image.id == photoid;
                });

                if (in_gallery) {
                    $(this).addClass("added");
                }
            }
        });

        $("#photos .gallery_list").attr("data-photoid", photoid).css({
            display: 'flex'
        });
    }

    function closeGallery() {
        $("#photos .gallery_list").attr("data-photoid", "").css({
            display: 'none'
        });
    }

    function addToGallery() {
        var photo_id = $(this).closest(".gallery_list").attr("data-photoid");
        var gallery_id = $(this).attr("data-index");
        var that = $(this);

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'addImageToGallery',
                photo_id: photo_id,
                gallery_id: gallery_id
            },
            success: function(response) {

                AppData.gallery = response.gallery;

                if (response.status == "limit_exceeded") {
                    Modal.alert(Language.getItem("photos-limit-exceeded").replace("#maxlimit#", AppData.modulsettings.gallery_maximgpergallery));
                } else {
                    if (response.status == "added") {
                        that.addClass("added");
                    }
                    if (response.status == "removed") {
                        that.removeClass("added");
                    }
                }

                Socket.Send("Gallery.BuildFromSource", null);
            }
        });
    }

    $(document)
        .on('click', '#photos .photoitem .user_photo', viewImage)
        .on('click', '#photos .select_image', selectImage)
        .on('click', '#photos .delete_image', deleteImage)
        .on('click', '#photos .add_to_gallery', openGalleryList)
        .on('click', '#photos .gallery_list .gallery_item', addToGallery)
        .on('click', '#photos .delete_bulk', deleteBulk)
        .on('click', '#photos .send_bulk', sendBulk)
        .on('click', '#photos .new', evtClickItem)
        .on('click', '#photos .gallery_list .close', closeGallery);
    return {
        Init: Init,
        evtClickItem: evtClickItem,
        gallerySelect: gallerySelect,
        buildFromSource: buildFromSource,
        renderList: renderList
    }
})();

var Translator = (function () {

    var $templateTranslatorItem;

    var sdpConstraints = {
        OfferToReceiveAudio : false,
        OfferToReceiveVideo : false

    };

    var mediaConstraints = {
        video : false,
        audio : true
    };

    var WebRTCState = {
        settings: {
        }
    };
    var WowzaWebRTCPublish = new wowzawebrtcpublish();

    var selectedId;
    var toTurnoff;
    var streamId;
    var clicked_item;
    var webRTCAdaptor;
    var webRTCInitialized = false;
    let state;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateTranslatorItem = $('template#tplTranslatorItem').remove().contents();
        BuildTranslator();

        if (AppData.profile.group == "translator") {
            if (AppData.modulsettings.translator_use_relay) {

                $("#landing .translator_buttons").removeClass("hidden");

                let main_lang = AppData.translator.find(function (item) {
                    return item.main_lang;
                });
                let floor_lang = AppData.translator.find(function (item) {
                    return item.is_floor;
                });

                let own_lang = AppData.translator.find(function (item) {
                    return item.assigned_translator.indexOf(AppData.profile.id) > -1;
                });

                if (own_lang != undefined) {

                    let fragment = $(document.createDocumentFragment());
                    let outputs = $("<div/>").addClass("relay_outputs");
                    let inputs = $("<div/>").addClass("relay_inputs");

                    let main_btn = $("<button/>").addClass("btn translator_main_lang translator_output_btn").attr("data-main_lang", main_lang.main_lang).html(main_lang.name[localState.lang]);
                    let own_btn = $("<button/>").addClass("btn translator_own_lang translator_output_btn active").attr("data-main_lang", own_lang.main_lang).html(own_lang.name[localState.lang]);

                    outputs.append('<div class="outputs_title">Output</div>');

                    outputs.append(main_btn);
                    outputs.append(own_btn);

                    let main_btn_in = $("<button/>").addClass("btn translator_main_in_lang translator_input_btn").attr("data-id", main_lang.id).html(main_lang.name[localState.lang]);
                    let floor_btn = $("<button/>").addClass("btn translator_floor_lang translator_input_btn").attr("data-id", floor_lang.id).html(floor_lang.name[localState.lang]);
                    let turnoff_btn = $("<button/>").addClass("btn translator_floor_lang translator_input_btn active").attr("data-id", "turnoffTranslation").html("Turn off");

                    inputs.append('<div class="inputs_title">Input</div>');

                    inputs.append(main_btn_in);
                    inputs.append(floor_btn);
                    inputs.append(turnoff_btn);

                    fragment.append(inputs);
                    fragment.append(outputs);

                    $("#landing .translator_buttons").html(fragment);

                    $("#landing .translator_output_btn").on("click", function () {
                        $("#landing .translator_output_btn").removeClass("active");
                        $(this).addClass("active");
                        let data = {
                            user_id: AppData.profile.id,
                            main_lang: $(this).attr("data-main_lang")
                        }

                        Socket.Send("Translator.setOwnLang", {
                            sender_lang: own_lang.id,
                            main_clicked: data.main_lang
                        }, {group: ["translator"]});
                        Socket.Send("TranslatorRelay.changeInputs", data);
                    });

                    $("#landing .translator_input_btn").on("click", function () {
                        $("#landing .translator_input_btn").removeClass("active");
                        $(this).addClass("active");

                        let id = $(this).attr("data-id");

                        Soundchannels.showDetails(id);
                    });
                }
            } else {
                $("#landing .translator_buttons").addClass("hidden");
            }
        } else {
            $("#landing .translator_buttons").remove();
        }
    }

    function setOwnLang(data) {

        let own_lang = AppData.translator.find(function(item) {
            return item.assigned_translator.indexOf(AppData.profile.id) > -1;
        });

        if (data.sender_lang == own_lang.id) {
            if (data.main_clicked == "true") {
                $("#landing .translator_output_btn").removeClass("active");
                $("#landing .translator_main_lang").addClass("active");
            } else {
                $("#landing .translator_output_btn").removeClass("active");
                $("#landing .translator_own_lang").addClass("active");
            }
        } else {
            if (data.main_clicked == "true") {
                $("#landing .translator_output_btn").removeClass("active");
                $("#landing .translator_own_lang").addClass("active");
            }
        }

    }

    function sort() {
        AppData.translator.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function BuildTranslator() {
        var $fragmentTranslator = $(document.createDocumentFragment()),
            $clone = $templateTranslatorItem.clone();

        // $clone.attr("data-id", "turnoffTranslation");
        // $clone.find('.data-name').attr("data-lang", "translator-turnoff").text(Language.getItem("translator-turnoff"));
        // $clone.appendTo($fragmentTranslator);

        sort();

        $.each(AppData.translator, function (i, el) {
            if (el.visible != undefined && el.visible) {
                $clone = $templateTranslatorItem.clone();
                $clone.attr("data-id", el.id);
                $clone.find('.data-name').html(el.name[localState.lang]).attr("data-id", el.id).attr("data-server_url", el.server_url);
                /*if (localState.currentBroadcast === el.id) {
                    $clone.addClass('color-primary');
                }*/
                $clone.appendTo($fragmentTranslator);
            }
        });

        $('#translator #translatorList').html($fragmentTranslator);
    }

    $(document).on('click', '#translatorList .item .publish', function (e) {
        e.stopPropagation();

        var that = $(this).closest(".item");
        clicked_item = that;

        selectedId = that.attr("data-id");
        if (selectedId != "turnoffTranslation") {
            toTurnoff = that.attr("data-id");
        }

        $("#translatorList .item").addClass("disabled");
        that.removeClass("disabled");

        if (streamId != undefined && webRTCInitialized) {
            $("#translatorList .item").removeClass("publishing");
            $("#translatorList .item .vol-settings i").addClass("hidden");
            stop();
            return;
        }

        if (selectedId == "turnoffTranslation" || (selectedId == toTurnoff && streamId != undefined)) {
            $("#translatorList .item").removeClass("publishing");
            $("#translatorList .item .vol-settings i").addClass("hidden");
            if (streamId != undefined && webRTCInitialized) {
                stop();
            }
            return;
        }

        var data = AppData.translator.find(function(el) {
            return el.id == selectedId;
        });

        writeDbLog("start", selectedId, "user_logs", "translator");

        console.info("---------------------------------", selectedId);

        streamId = data.stream_name;

        state = {
            publishing: false,
            pendingPublish: false,
            pendingPublishTimeout: undefined,
            muted: false,
            video: false,
            selectedCam: '',
            selectedMic: '',
            settings: {
                sdpURL: data.signaling_url,
                applicationName: data.application_name,
                streamName: data.stream_name,
                audioBitrate: "64",
                audioCodec: "opus",
                videoBitrate: "3500",
                videoCodec: "42e01f",
                videoFrameRate: "30",
                frameSize: "default"
            }
        };

        if (!webRTCInitialized) {
            init(onPublishPeerConnected, onPublishPeerConnectionFailed, onPublishPeerConnectionStopped, errorHandler);
            update(state.settings);
        } else {
            webRtcInitialized();
            update(state.settings);
            start();
            that.addClass("publishing");
            $(".vol-settings i.unmuted", clicked_item).removeClass("hidden");
        }

        //Layout.hide("translator");
    });

    const init = (connected,failed,stopped,errorHandler) => {
        WowzaWebRTCPublish.on({
            gotDevices: () => {
            },
            devicesReady: () => {
                if (!webRTCInitialized) {
                    webRTCInitialized = true;
                    start();
                }
            },
            onStateChanged: (newState) => {
                console.log("WowzaWebRTCPublish.onStateChanged");
                console.log(newState);

                // LIVE / ERROR Indicator
                if (newState.connectionState === 'connected')
                {
                    connected();
                }
                else if (newState.connectionState === 'failed')
                {
                    failed();
                }
                else
                {
                    stopped();
                }
            },
            onCameraChanged: (cameraId) => {
                if (cameraId !== state.selectedCam)
                {
                    state.selectedCam = cameraId;
                    let camSelectValue = 'CameraMobile_'+cameraId;
                    if (cameraId === 'screen') camSelectValue = 'screen_screen';
                }
            },
            onMicrophoneChanged: (microphoneId) => {
                if (microphoneId !== state.selectedMic)
                {
                    state.selectedMic = microphoneId;
                }
            },
            onError: errorHandler
        });

        WowzaWebRTCPublish.set({
            videoElementPublish: $("#translator #localVideo"),
            useSoundMeter:false
        });
        //start();
    };

    const getState = () => {
        return state;
    }

    // throw errors with these messages
    const okToStart = () => {
        if (state.settings.sdpURL === "")
        {
            throw "No stream configured.";
        }
        else if (state.settings.applicationName === "")
        {
            throw "Missing application name.";
        }
        else if (state.settings.streamName === "")
        {
            throw "Missing stream name."
        }
        return true;
    }

    const updateFrameSize = (frameSize) => {
        let constraints = JSON.parse(JSON.stringify(WowzaWebRTCPublish.getState().constraints));
        if (frameSize === 'default')
        {
            constraints.video["width"] = { min: "640", ideal: "1280", max: "1920" };
            constraints.video["height"] = { min: "360", ideal: "720", max: "1080" };
        }
        else
        {
            constraints.video["width"] = { exact: frameSize[0] };
            constraints.video["height"] = { exact: frameSize[1] };
        }
        WowzaWebRTCPublish.set({constraints: constraints});
    }

    const update = (settings) => {
        state.settings = settings;
        return WowzaWebRTCPublish.set(settings);
    }

    // start/stop publisher
    const start = () => {
        if(okToStart()){
            WowzaWebRTCPublish.start();

            webRtcInitialized();
            clicked_item.addClass("publishing");
            console.log(clicked_item);
            console.log($("#translatorList .vol-settings i.unmuted", clicked_item));
            $(".vol-settings i.unmuted", clicked_item).removeClass("hidden");
        }
    };

    const stop = () => {
        WowzaWebRTCPublish.stop();
        $("#translatorList .item").removeClass("disabled");
        $("#translatorList .item .vol-settings i").addClass("hidden");
        turnOffSignal();
    };

    const videoOn = () => {
        WowzaWebRTCPublish.setVideoEnabled(true);
    }
    const videoOff = () => {
        WowzaWebRTCPublish.setVideoEnabled(false);
    }

    const audioOn = () => {
        WowzaWebRTCPublish.setAudioEnabled(true);
    }

    const audioOff = () => {
        WowzaWebRTCPublish.setAudioEnabled(false);
    }

    // Helpers

    const errorHandler = (error) => {
        let message;
        if(error.name == "OverconstrainedError"){
            message = "Your browser or camera does not support this frame size";
            updateFrameSize("default");
        } else if ( error.message ) {
            message = error.message;
        }
        else {
            message = error
        }
        console.log(message);
        stop();
    };

    const setPendingPublish = (pending) =>
    {
        if (pending)
        {
            state.pendingPublish = true;
            state.pendingPublishTimeout = setTimeout(()=>{
                stop();
                errorHandler({message:"Publish failed. Unable to connect."});
                setPendingPublish(false);
            },10000);
        }
        else
        {
            state.pendingPublish = false;
            if (state.pendingPublishTimeout != null)
            {
                clearTimeout(state.pendingPublishTimeout);
                state.pendingPublishTimeout = undefined;
            }
        }
    }

    const onPublishPeerConnected = () => {
        state.publishing = true;
        setPendingPublish(false);
    }

    const onPublishPeerConnectionFailed = () => {
        setPendingPublish(false);
        onPublishPeerConnected();
        errorHandler({message:"Peer connection failed."});
        $("#translatorList .item").removeClass("disabled");
        $("#translatorList .item .vol-settings i").addClass("hidden");
        turnOffSignal();
    }

    const onPublishPeerConnectionStopped = () => {
        if (!state.pendingPublish)
        {
            state.publishing = false;
        }
    }


    $(document).on('click', '#translatorList .item .vol-settings .unmuted', function (e) {
        e.stopPropagation();

        var parent = $(this).closest(".item");

        state.muted = true;
        audioOff();

        $(this).addClass("hidden");
        $(".muted", parent).removeClass("hidden");
    });

    $(document).on('click', '#translatorList .item .vol-settings .muted', function (e) {
        e.stopPropagation();

        var parent = $(this).closest(".item");

        state.muted = false;
        audioOn();

        $(this).addClass("hidden");
        $(".unmuted", parent).removeClass("hidden");
    });

    function webRtcInitialized() {
        Socket.Send("Soundchannels.showOnAir", selectedId, {client: "client"});
    }

    function turnOffSignal() {
        Socket.Send("Soundchannels.streamFinished", toTurnoff, {client: "client"});
        streamId = undefined;
        selectedId = undefined;
        toTurnoff = undefined;
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getTranslator'
        }, function (data) {
            AppData.translator = data;
            BuildTranslator();
        });
    }

    function showDetails(id) {

    }

    function toggleRelayBtns() {
        if (AppData.modulsettings.translator_use_relay) {
            $("#landing .translator_buttons").removeClass("hidden");
        } else {
            $("#landing .translator_buttons").addClass("hidden");
        }
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showDetails: showDetails,
        setOwnLang: setOwnLang,
        toggleRelayBtns: toggleRelayBtns
    }
})();

var Broadcast = (function () {

    var $templateBroadcastItem, playingMenuStreamURL;

    var WebRTCState = {
        settings: {
        }
    };

    var WowzaWebRTCPlay = new wowzawebrtc();

    let statePrefix = 'play';
    let attentionTimeout;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateBroadcastItem = $('template#tplBroadcastItem').contents(); // remove().
        BuildBroadcast();

        if(localState.currentBroadcast === undefined)
            localState.currentBroadcast = "";

        setRunawayDatas();

        videojs('my-video', {
            html5: {
                dash: {
                    "liveDelay": 2
                }
            }
        }).ready(function() {
            let myPlayer = this;

            myPlayer.on("fullscreenchange", function(){
                if (myPlayer.isFullscreen() && AppData.modulsettings.attentionchecker) {
                    myPlayer.exitFullscreen();
                }
            });

            myPlayer.on('pause', function() {
                $("#playerElement").find(".vjs-big-play-button").removeClass('vjs-hidden').show();
            });

            myPlayer.on('play', function() {
                $("#playerElement").find(".vjs-big-play-button").hide();
            });

            myPlayer.on('canplay', function() {
                showTexttracks();
            });
        });

        playingMenuStreamURL = false;

        if(AppData.profile.group !== "speaker" && AppData.profile.group !== "director") {
            if ($("#landing").find("#playerElement:visible").length !== 1 && AppData.modulsettings.showonstart) {
                Broadcast.showDetails(AppData.modulsettings.showonstreamurl);
                if (localState.layout.length !== 0) {
                    minifyPlayer();
                }
            }
        }
    }

    function minifyPlayer() {
        hideTranslator();
        hideClose();
        hideChatButton();
        hideReactions();
        $(".videostream").appendTo($("body"));
        $(".videostream").addClass("min");
    }

    function restorePlayer() {
        $(".videostream").removeClass("min");
        showTranslator();
        showChatButton();
        showClose();
        showReactions();
    }

    function setRunawayDatas() {
        if(AppData.modulsettings.runawayBackground !== undefined && AppData.modulsettings.runawayBackground !== "") {
            var runawayBackground = AppData.medias.filter(function (e) {
                return e.id === AppData.modulsettings.runawayBackground;
            })[0];
            $(".videostream #runawaycover").css('background-image', 'url("' + runawayBackground.files[localState.lang].file + '")');
        } else {
            $(".videostream #runawaycover").css('background-image', 'none');
        }
    }

    function setBroadcastPoster() {
        var streamplayer = videojs('my-video');
        if(AppData.modulsettings.showBroadcastThumbnail && AppData.modulsettings.broadcastThumbnail !== undefined && AppData.modulsettings.broadcastThumbnail !== "") {
            var poster = AppData.medias.filter(function (e) {
                return e.id === AppData.modulsettings.broadcastThumbnail;
            })[0];
            streamplayer.poster(poster.files[localState.lang].file);
        } else {
            streamplayer.poster('');
        }
    }

    function playVideoStream(param) {
        let url = AppData.broadcast.find(function (e){
            return e.id == param.streamId;
        });
        if (param.pushed) {
            if (!trueconf_opened) {
                startWatching(url.streamurl);
            }
        } else {
            startWatching(url.streamurl);
        }

    }

    function stopVideoStream() {

        writeDbLog("stop", "", "user_logs", "broadcast");

        stopVideoStreamHLS();
        stopWebRTC();
        Soundchannels.stopWebRTC();

        disableAttentionGuard();

        $("#default_nav.floating").removeClass("higher");

        $(".videostream").appendTo($(".videostream").parent());

        localState.currentBroadcast = "";
        localState.currentBroadcastType = "";
        $("#broadcastList").find(".item").removeClass("color-primary");
    }

    function stopVideoStreamHLS() {

        var streamplayer = videojs('my-video');
        streamplayer.reset();
        if ($("#playerElement:visible").length === 1) {
            $("#playerElement").hide();
            $(".videostream").hide();

            $(".stream_reactions").addClass("hidden");

            Layout.setMainSizes();
            if (AppData.modulsettings.showredalert) {
                $("#landing").find('.open-main.broadcast').removeClass("color-primary").addClass('blinkingicon');
                $("#landing").find('.open-main.broadcast').css("color", AppData.settings.colorPrimary);
            }
        }
    }

    function showRunaway() {

        var video = $("#my-video_html5_api").get(0);
        if(video != undefined) {
            if (!video.paused && $(".videostream #runawaycover:visible").length !== 1) {
                $(".videostream #runawaycover")
                    .removeClass('animated faster fadeOutRight').addClass('animated faster fadeInRight')
                    .show();
                if (!video.paused) video.pause();
            } else if(video.paused && $(".videostream #runawaycover:visible").length === 1) {
                $(".videostream #runawaycover")
                    .on("animationend animationcancel", function (e) {
                        var target = e.target || e.originalEvent.target;
                        if (target === this) {
                            $("#runawaycover").off("animationend animationcancel").removeClass('animated faster fadeOutRight').hide();
                        }
                    })
                    .removeClass('animated faster fadeInRight')
                    .addClass('animated faster fadeOutRight'); //.hide();
                if (video.paused) video.play();
            }
        }
    }

    function playWebRTCStream(selectedId = '') {

        stopVideoStream();

        var data = AppData.broadcast.filter(function(el) {
            return el.id == selectedId;
        })[0];

        writeDbLog("start WebRTC", selectedId, "user_logs", "broadcast");

        if(data) {
            WebRTCState = {
                settings: {
                    playSdpURL: data.realtimeserversignalingurl,
                    playApplicationName: data.realtimestreamurl,
                    playStreamName: data.realtimeserverstreamid
                }
            };
            init(WebRTCerrorHandler,onPlayPeerConnected,onPlayPeerConnectionStopped);

            Navigation.hideMenu();

            localState.currentBroadcast = selectedId;
            localState.currentBroadcastVideoType = 'realtime';
            BuildBroadcast();
        }
    }

    const init = (errorHandler,connected,stopped) => {
        startWebRTC(WebRTCState.settings);
        WowzaWebRTCPlay.on({
            onError:errorHandler,
            onStateChanged: (state) => {
                console.log("state.connectionState", state.connectionState);
                if (state.connectionState === 'connected') {
                    console.log("connected in");
                    connected();
                }else {
                    stopped();
                    console.log("stopped in");
                }
            }
        });
        WowzaWebRTCPlay.set({
            videoElementPlay:document.getElementById('my-video23') // _html5_api'),
        });
    }

    const getWebRTCState = () => {
        return WebRTCState;
    }

    const startWebRTC = (settings) => {
        updateWebRTC(settings).then(() => {
            WowzaWebRTCPlay.play();
            $(".videostream").show();
            $("#playerElement").hide();
            $("#playerElement2").show();
            $('#my-video23').show();
        });
    }

    const stopWebRTC = () => {
        if (WowzaWebRTCPlay.wowzaPeerConnectionPlay != undefined) {
            WowzaWebRTCPlay.stop();
        }

        $(".videostream").hide();

        $(".stream_reactions").addClass("hidden");

        $("#playerElement2").hide();
    }

    const updateWebRTC = (settings) => {
        WebRTCState.settings = settings;
        let sendSettings = {};
        for (let key in settings)
        {
            let sendKey = key.substring(statePrefix.length);
            sendKey = sendKey[0].toLowerCase() + sendKey.slice(1);
            sendSettings[sendKey] = settings[key];
        }
        return WowzaWebRTCPlay.set(sendSettings);
    }

    const onPlayPeerConnected = () => {
        WebRTCState.playing = true;
    }

    const onPlayPeerConnectionStopped = () => {
        WebRTCState.playing = false;
    }

    const WebRTCerrorHandler = (error) => {
        let message;
        if ( error.message ) {
            message = error.message;
        }
        else {
            message = error
        }
        alert(message);
    };

    function sort() {
        AppData.broadcast.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function BuildBroadcast() {
        var $fragmentBroadcasts = $(document.createDocumentFragment()),
            $clone = $templateBroadcastItem.clone(),
            showchoosingplaylisttousers;

        $clone.attr("data-id", "turnoffvideo");
        $clone.find('.data-name').text(Language.getItem('broadcast-turnoff')).attr("data-id", "turnoffvideo");
        $clone.appendTo($fragmentBroadcasts);

        sort();

        $.each(AppData.broadcast, function (i, el) {
            showchoosingplaylisttousers = AppData.modulsettings.showchoosingplaylisttousers === true && el.realtimestreamurl != undefined && el.realtimestreamurl != "";
            if (el.visible != undefined && el.visible) {
                $clone = $templateBroadcastItem.clone();
                $clone.attr("data-id", el.id).attr("data-streamurl", el.streamurl).attr("data-playertype", "hls");
                $clone.find('.data-name').html(el.name[localState.lang] + (showchoosingplaylisttousers ? " (HLS)" : "")).attr("data-playertype", "hls").attr("data-id", el.id).attr("data-streamurl", el.streamurl);
                if (localState.currentBroadcast === el.id && localState.currentBroadcastVideoType === 'hls') {
                    $clone.addClass('color-primary');
                }
                $clone.appendTo($fragmentBroadcasts);
                if(showchoosingplaylisttousers) {
                    $clone = $templateBroadcastItem.clone();
                    $clone.attr("data-playertype", "realtime").attr("data-id", el.id).attr("data-streamurl", el.streamurl)
                    $clone.find('.data-name').text(el.name[localState.lang] + " (Realtime)").attr("data-playertype", "realtime").attr("data-id", el.id).attr("data-streamurl", el.streamurl);
                    if (localState.currentBroadcast === el.id && localState.currentBroadcastVideoType === 'realtime') {
                        $clone.addClass('color-primary');
                    }
                    $clone.appendTo($fragmentBroadcasts);
                }
            }
        });
          $('#broadcast #broadcastList').html($fragmentBroadcasts);
    }

    function playHLS(streamurl) {

        let url = AppData.broadcast.find(function (e){
            return e.id == streamurl.streamId;
        });

        stopVideoStream();

        localState.currentBroadcast = streamurl.streamId;

        startWatching(url.streamurl);

        Navigation.hideMenu();
    }

    function playStreamByURL(streamurl) {
        localState.currentBroadcast = streamurl;
        BuildBroadcast();
        if(AppData.modulsettings.videoplayertype === "realtime") {
            playWebRTCStream(streamurl)
        } else {
            startWatching(streamurl);
        }
    }

    function playStreamByClick(e) {
        stopVideoStream();
        var streamurl = '';
        if(e !== undefined) {
            localState.currentBroadcast = e.target.tagName == "I" ? e.target.nextSibling.nextSibling.dataset.id : e.target.tagName == "DIV" ? e.target.children[1].dataset.id : e.target.dataset.id;
            localState.currentBroadcastVideoType = 'hls';
            if(localState.currentBroadcast == "turnoffvideo") {

                $("#default_nav").find("[data-target='broadcast']").css("border", '');

                stopVideoStream();
                return;
            }
            streamurl = e.target.dataset.streamurl;
            BuildBroadcast();
        } else {
            var streamurls = AppData.broadcast.filter(function(e) {
                return e.visible === true;
            });
            if(streamurls.length === 1) {
                localState.currentBroadcast = streamurl = streamurls[0].streamurl;
            } else {
                var streamurlf = AppData.broadcast.filter(function(e) {
                    return e.id === AppData.modulsettings.showonstreamurl;
                });
                if(streamurlf.length !== 0 && streamurlf[0].visible)
                    localState.currentBroadcast = streamurl = streamurlf[0].streamurl;
            }
        }

        if(streamurl != '') {
            //$("video source").attr('src', streamurl); // + '#t=1'
            startWatching(streamurl);

            Navigation.hideMenu();
        }
    };

    $(document).on('click', '#broadcastList .item', function (e) {
        var videotype;

        if(AppData.modulsettings.showchoosingplaylisttousers === true) {
            //videotype = e.target.dataset.playertype;
            videotype = $(this).attr("data-playertype");
        } else {
            videotype = AppData.modulsettings.videoplayertype;
        }
        if (videotype === "realtime") {
            //playWebRTCStream(e.target.dataset.id);
            playWebRTCStream($(this).attr("data-id"));
        } else {
            playStreamByClick(e);
        }
        Layout.hide("broadcast");
    });

    $(document).on('click', '#landing .landing_elements .reactions .reaction', function (e) {
        let icon = $(this).attr("data-icon");
        let color = $(this).attr("data-color");

        let _this = $(this);
        $(this).addClass("clicked");
        setTimeout(function (){
            _this.removeClass("clicked");
        }, 150);
        Socket.Send("sendReaction", {uid: AppData.profile.id, type: icon, color: color}, {
            client: 'audience'
        })
    });

    /*$(document).on('click', '#landing .landing_elements .translator_btn', function (e) {
        Layout.show("soundchannels");
    });*/

    $(document).on('click', '#landing .landing_elements .closebroadcast_btn', function (e) {
        $("#default_nav").find("[data-target='broadcast']").css("border", '');

        stopVideoStream();
        playingMenuStreamURL = false;
    });

    $(document).on("click", "#landing .countdown_bc .bc_button .open-broadcast", function(){
        if (AppData.modulsettings.bc_landingbutton_target) {
            $("#default_nav [data-target='broadcast'][data-id='"+AppData.modulsettings.bc_landingbutton_target+"']").trigger("mouseup");
        }
    });

    function showDetails(id) {

        if (id) {
            var streamurl = AppData.broadcast.filter(function(e) {
                return e.id == id;
            });
            if (localState.broadcast != undefined) {
                if (streamurl !== localState.broadcast.itemIndex || localState.broadcast.itemIndex === 0) {
                    localState.broadcast.itemIndex = String(streamurl);
                }
            } else {
                localState.broadcast = {'itemIndex': String(streamurl)};
            }

            if(playingMenuStreamURL) {
                stopVideoStream();
                playingMenuStreamURL = false;
            } else {
                if (AppData.modulsettings.videoplayertype === "realtime") {
                    //playWebRTCStream(e.target.dataset.id);
                    playWebRTCStream(id);
                } else {
                    playStreamByURL(streamurl[0].streamurl);
                }
                playingMenuStreamURL = true;
            }
        }
    }

    function startWatching(streamurl) {

        let bc = AppData.broadcast.find(function(item) {
            return item.streamurl == streamurl;
        });

        writeDbLog("start", (bc !== undefined ? bc.id : streamurl), "user_logs", "broadcast");

        let secure_url = "";

        if (bc) {
            Channels.setChannel(bc.channel)
            if (localState.broadcast == undefined) {
                localState.broadcast = {itemIndex: 0};
            }
            localState.broadcast.itemIndex = bc.id;

            if (bc.secure) {
                secure_url = bc.secure_url;
            }
        }

        var streamplayer = videojs('my-video');
        streamplayer.reset();
        if(AppData.modulsettings.showBroadcastThumbnail && AppData.modulsettings.broadcastThumbnail !== undefined && AppData.modulsettings.broadcastThumbnail !== "") {
            var poster = AppData.medias.filter(function (e) {
                return e.id === AppData.modulsettings.broadcastThumbnail;
            })[0];
            streamplayer.poster(poster.files[localState.lang].file);
        } else {
            streamplayer.poster('');
        }

        let streamsrc = streamurl+'?siteid='+AppData.settings.site_id+'&uid='+AppData.profile.id+secure_url;

        if (bc.use_load_balancer) {

            var xhr = new XMLHttpRequest();
            xhr.open('GET', streamurl, true);
            xhr.onload = function () {
                streamsrc = xhr.responseURL+'?siteid='+AppData.settings.site_id+'&uid='+AppData.profile.id+secure_url;
                streamplayer.reset();
                streamplayer.src(streamsrc);
                streamplayer.load();
                streamplayer.play();
            };
            xhr.send();
        } else {
            streamplayer.reset();
            streamplayer.src(streamsrc);
            streamplayer.load();
            streamplayer.play();
            $(".stream").show();
            $("#playerElement").show();
            $(".videostream").show();
        }
        $(".stream").show();
        $("#playerElement").show();
        $(".videostream").show();

        if (AppData.modulsettings.show_emoji_reactions) {
            //$(".stream_reactions").appendTo($(".landing_elements .videostream"));
            $(".stream_reactions").removeClass("hidden");
        } else {
            $(".stream_reactions").addClass("hidden");
        }

        logVideoWatch('start_watching', streamurl+'?siteid='+AppData.settings.site_id+'&uid='+AppData.profile.id);

        $("#default_nav.floating").addClass("higher");

        if (AppData.modulsettings.attentionchecker) {
            $("#playerElement .vjs-fullscreen-control").hide();
            $("#playerElement2 .vjs-fullscreen-control").hide();
            startAttentionGuard();
        } else {
            $("#playerElement .vjs-fullscreen-control").show();
            $("#playerElement2 .vjs-fullscreen-control").show();
        }

        showReactions();
        showTranslator();
        showChatButton();
        showTexttracks();
    }

    function startAttentionGuard() {
        let min = parseInt(AppData.modulsettings.attentionchecker_min);
        let max = parseInt(AppData.modulsettings.attentionchecker_max);

        if (isNaN(min)) {
            min = 10;
        }
        if (isNaN(max)) {
            max = 30;
        }

        min = min * 1000;
        max = max * 1000;

        if (!AppData.modulsettings.attentionchecker_test) {
            min *= 60;
            max *= 60;
        }

        let time = Math.floor(Math.random() * (max - min + 1)) + min;

        attentionTimeout = setTimeout(function (){
            console.log("shown");

            $(".attention_btn").remove();
            let btn = $("<button/>").addClass("btn attention_btn").html('<i class="fas fa-eye"></i>&nbsp;' + Language.getItem("attention-btn"));
            btn.appendTo($("body"));
            let btnW = btn.outerWidth();
            let btnH = btn.outerHeight();

            let left = Math.floor(Math.random() * (($(window).width()  - btnW) - 0 + 1)) + 0;
            let top = Math.floor(Math.random() * (($(window).height()  - btnH) - 0 + 1)) + 0;

            btn.css({
                top: top,
                left: left
            });

            btn.on("click", function (){
                $.ajax({
                    url: "api/",
                    method: "post",
                    data: {
                        do: "saveAttentionData",
                        action_type: "CLICKED",
                        broadcast_id: localState.currentBroadcast
                    },
                    complete: function(resp) {
                        btn.remove();
                    }
                });
            });

            $.ajax({
                url: "api/",
                method: "post",
                data: {
                    do: "saveAttentionData",
                    action_type: "SHOWN",
                    broadcast_id: localState.currentBroadcast
                },
                complete: function(resp) {
                    if (AppData.modulsettings.attentionchecker) {
                        startAttentionGuard();
                    }

                    let hideSecs = parseInt(AppData.modulsettings.attentionchecker_hide);
                    if (isNaN(hideSecs)) {
                        hideSecs = 0;
                    }

                    if (hideSecs > 0) {
                        setTimeout(function () {
                            btn.remove();
                        }, hideSecs * 1000);
                    }
                }
            });
        }, time);
    }

    function disableAttentionGuard() {
        $("#playerElement .vjs-fullscreen-control").show();
        $("#playerElement2 .vjs-fullscreen-control").show();
        $(".attention_btn").remove();
        clearTimeout(attentionTimeout);
    }

    function enableAttentionGuard() {
        $("#playerElement .vjs-fullscreen-control").hide();
        $("#playerElement2 .vjs-fullscreen-control").hide();
        clearTimeout(attentionTimeout);
        var streamplayer = videojs('my-video');
        if (streamplayer.isFullscreen()) {
            streamplayer.exitFullscreen();
        }
        if ($("#playerElement:visible").length === 1 || $("#playerElement2:visible").length === 1) {
            startAttentionGuard();
        }
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getBroadcast'
        }, function (data) {
            AppData.broadcast = data;
            Init();
        });
    }

    function showReactions() {
        if (AppData.modulsettings.allowreactions) {
            $("#landing .landing_elements .videostream .reactions").show();
        } else {
            $("#landing .landing_elements .videostream .reactions").hide();
        }
    }

    function showChatButton() {
        if (AppData.modulsettings.showchatbutton) {
            $('#landing .landing_elements .broadcast_showmsgboard_btn').show();
        } else {
            $('#landing .landing_elements .broadcast_showmsgboard_btn').hide();
        }
    }

    function showTranslator() {
        if (AppData.modulsettings.showtransbutton) {
            $('#landing .landing_elements .videostream .translator_btn').show();
        } else {
            $('#landing .landing_elements .videostream .translator_btn').hide();
        }
    }

    function hideTranslator() {
        $('#landing .landing_elements .videostream .translator_btn').hide();
    }

    function hideClose() {
        $('#landing .landing_elements .closebroadcast_btn').hide();
    }

    function showClose() {
        $('#landing .landing_elements .closebroadcast_btn').show();
    }

    function hideChatButton() {
        $('#landing .landing_elements .broadcast_showmsgboard_btn').hide();
    }

    function hideReactions() {
        $("#landing .landing_elements .videostream .reactions").hide();
    }


    function showTexttracks() {
        if (AppData.modulsettings.available_captions && AppData.modulsettings.available_captions != "") {

            let langs = AppData.modulsettings.available_captions.split(",");

            $('#playerElement .vjs-subs-caps-button').show();
            let tracks = videojs("my-video").remoteTextTracks();

            let track_selectors = $("#playerElement .vjs-subs-caps-button .vjs-subtitles-menu-item");
            track_selectors.hide();

            for (let i = 0; i < tracks.length; i++) {
                let id = tracks[i].id;

                for (let j = 0; j < track_selectors.length; j++) {
                    let title = $(track_selectors[j]).find(".vjs-menu-item-text");

                    if (langs.indexOf(tracks[i].language) > -1 && title.html() == id) {
                        title.parent().show();
                        break;
                    }
                }
            }
        } else {
            $('#playerElement .vjs-subs-caps-button').hide();
        }
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        playVideoStream: playVideoStream,
        stopVideoStream: stopVideoStream,
        BuildBroadcast: BuildBroadcast,
        playStreamByClick: playStreamByClick,
        playStreamByURL: playStreamByURL,
        playHLS:playHLS,
        stopVideoStreamHLS:stopVideoStreamHLS,
        playWebRTCStream:playWebRTCStream,
        showDetails: showDetails,
        setRunawayDatas: setRunawayDatas,
        showRunaway: showRunaway,
        setBroadcastPoster: setBroadcastPoster,
        disableAttentionGuard: disableAttentionGuard,
        enableAttentionGuard: enableAttentionGuard,
        showReactions: showReactions,
        showTranslator: showTranslator,
        showChatButton: showChatButton,
        hideTranslator: hideTranslator,
        hideClose: hideClose,
        showClose: showClose,
        showTexttracks: showTexttracks,
        minifyPlayer: minifyPlayer,
        restorePlayer: restorePlayer
    }
})();

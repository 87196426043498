/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var Profile = (function () {

    $("#gdpr").attr("style", "display: none !important");

    var firstInit = true;

    function Init() {
        var $self = $('#profile article');
        $.each(AppData.profile, function (i, val) {
            var $input = $self.find('[name="' + i + '"]');

            if (typeof val === 'object') {
                val = val[localState.lang];
            }

            if ($input.is('[type=checkbox]')) {
                $input.prop('checked', val);
            } else {
                $input.val(val);
            }
            $input = null;
        });
        if (AppData.profile && AppData.profile.img) {
            $self.find('.data-img').attr('data-src', 'upload/users/' + AppData.profile.img + '?r=' + Math.random());
        }

        if(firstInit) {
            buildLanguages();
            firstInit =false;
        }

        if (AppData.modulsettings.usefnamelname) {
            $("[name='name']").closest("fieldset").hide();
            $("[name='first_name']").closest("fieldset").show();
            $("[name='last_name']").closest("fieldset").show();
        } else {
            $("[name='name']").closest("fieldset").show();
            $("[name='first_name']").closest("fieldset").hide();
            $("[name='last_name']").closest("fieldset").hide();
        }

        if (AppData.modulsettings.showchangepass) {
            $("#profile .change-pass-cont").show();
        } else {
            $("#profile .change-pass-cont").hide();
        }

        addUserFields(AppData.profile);

        if (AppData.modulsettings.showsociallinks) {
            $("#profile .social").show();
        } else {
            $("#profile .social").hide();
        }

        $('#hamburger').find('.data-user').text(AppData.profile.name[localState.lang]);

        $self.find('.changeLang').val(localState.lang);
        $self.toggleClass('disabled', $.isEmptyObject(AppData.profile));
        $self.find('.privacy').toggle(AppData.modulsettings.showprivacy);

        $self = null;
    }

    function sort() {
        AppData.user_fields.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function addUserFields(data) {
        sort();
        var user_fields = AppData.user_fields.filter(function(el) {
                return el.is_active === true;
            }),
            input,
            label,
            legend,
            fieldset;

        $("#profile .custom_fields").html("");
        $.each(user_fields, function(i,el) {
            fieldset = $("<fieldset/>");
            legend = $("<legend/>");
            legend.html(el.label[localState.lang]);
            fieldset.append(legend);
            label = $("<label>");
            label.attr("class", "flex");

            if(el.field_type !== undefined) {
                switch (el.field_type) {
                    case "textarea":
                        input = $("<textarea/>");
                        input.attr("rows", "10").attr("maxlength", "1000").attr("autocapitalize", "sentences");
                        break;
                    case "text":
                    case "social":
                    default:
                        input = $("<input/>");
                        input.attr("type", "text");
                        break;
                }
            }
            if(el.field_required) {
                input.attr("required", "true");
            }
            label.append(input);
            if(el.field_type === "social") {
                fieldset.attr("class", "social");
            }
            fieldset.append(label);
            if(el.field_translated) {
                input.attr("name", "valuestr[" + el.field_name + "]").attr("spellcheck", "true");
                if(data && data.values[el.field_name] !== undefined) {
                    input.val(data.values[el.field_name].title[localState.lang]);
                }
            } else {
                input.attr("name", "values[" + el.field_name + "]").attr("spellcheck", "true");
                if(data && data.values[el.field_name] !== undefined) {
                    input.val(data.values[el.field_name].title);
                }
            }
            $("#profile .custom_fields").append(fieldset);
        });
    }

    function buildLanguages() {
        var $select_lang = $(".changeLang"),
            langFragment = $(document.createDocumentFragment()),
            languages;

        $.post('api/', {'do': 'getTranslations'}, function(data) {
            AppData.translations = data;
            languages = AppData.translations.filter(function(e) {
                    return e.active === true;
            });
            languages.sort(function(a,b){
               return parseInt(a.sort_id) >parseInt(b.sort_id) ? 1 : -1;
            });
            $.each(languages, function (i, e) {
                langFragment.append('<option value="' + e.translation_id + '" ' + (localState.lang === e.translation_id ? 'selected' : '') + '>' + e.translation_name + '</option>');
            });
            $select_lang.html(langFragment);
        });

    }

    function readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#profile .data-img').attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function uploadImg(input) {
        var
            $wrapper = $('#profile .avatar-wrapper'),
            $img = $wrapper.find('img'),
            $actions = $wrapper.find('.upload, .remove'),
            $wait = $wrapper.find('figcaption');
        if (input.files && input.files[0]) {
            var formData = new FormData();
            formData.append('do', 'uploadAvatar');
            formData.append('image', input.files[0]);
            $.ajax({
                url: 'api/',
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
                success: function (data) {
                    //$.get(data, function () {
                        // Attendees.BuildFromSource();
                        writeDbLog("profile img uploaded", "","user_logs", "users");
                        Socket.Send('Attendees.updateAvatar', {img: data, id: AppData.profile.id}, {
                            client: 'client'
                        });
                        Socket.Send('Users.updateAvatar', {img: data, id: AppData.profile.id}, {
                            client: 'admin'
                        });
                        $img.attr('src', data);
                        $actions.removeClass('animated zoomOut fast').addClass('animated bounceIn fast');
                        $img.show();
                        $wait.text('');
                        $('div.main').filter('#hamburger').find('.data-img').attr('src', data);
                        //Toast.show( 'Profilkép feltöltése sikeres.', 'success' );
                    //});
                },
                error: function () {
                    $actions.removeClass('animated zoomOut fast').addClass('animated bounceIn fast');
                    $img.show();
                    $wait.text('');
                    //Toast.show( 'Profilkép feltöltése sikertelen.', 'error' );
                },
                complete: function () {
                    //$wrapper = $actions = $img = $wait = null;
                },
                xhr: function () {
                    var xhr = $.ajaxSettings.xhr();
                    xhr.upload.onprogress = function (e) {
                        if (e.lengthComputable) {
                            var percentage = Math.round(100 * e.loaded / e.total);
                            $wait.text(percentage);
                            if (percentage === 100) {
                                $wait.text(100);
                            }
                        }
                    };
                    return xhr;
                }
            });
            requestAnimationFrame(function () {
                $actions.removeClass('animated bounceIn fast').addClass('animated zoomOut fast');
                $img.hide();
            });
        }
    }

    function BuildFromSource(img) {
        $('#hamburger').find('.data-img').attr('src', img + '?r=' + Math.random());
        $('#profile article').find('.data-img').attr('src', img + '?r=' + Math.random());
    }

    function DeleteProfilePictures() {
        $('#hamburger').find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
        $('#profile .data-img').attr('src', 'img/profile-picture-placeholder.png');
    }

    //$( document ).on( 'load input', '#profile textarea', resizeTextarea );
    $(document).on('click', '.change-pass', function () {
        $("#changepass-details input").val('');
        Layout.show('changepass-details');
    });
    $(document).on('change', '#profile select.changeLang', function () {
        Language.Init(this.value);
    });
    $(document).on('change', '#profile .uploadImage', function () {
        //readURL( this );
        uploadImg(this);
        this.value = '';
    });
    $(document).on('change', '#profile .privacy input', function () {
        var
            $this = $(this),
            $toggles = $this.closest('fieldset').find('input');
        $this.siblings('input[type=hidden]').val($this.prop('checked'));
        if ($this.is($toggles.first()) && !$this.prop('checked')) {
            $toggles.not($this).prop('checked', false);
        }
        if (!$this.is($toggles.first()) && $this.prop('checked')) {
            $toggles.first().prop('checked', true);
        }
        $this = $toggles = null;
    });
    $(document).on('click', '#profile .remove', function () {
        Modal.confirm(Language.getItem('profile-delete-image'), function () {
            $.post('api/', {
                do: 'removeAvatar'
            }, function (data) {
                $('#profile .data-img').attr('src', 'img/profile-picture-placeholder.png');
                $('#hamburger').find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
                //Attendees.BuildFromSource();
                Socket.Send('Attendees.updateAvatar', {id: AppData.profile.id, img: 'img/profile-picture-placeholder.png'}, {
                    client: 'client'
                });
                Socket.Send('Users.BuildFromSource', true, {
                    client: 'admin'
                });
            });
        });
    });
    $(document).on('reset', '#profile #formProfile', function (e) {
        e.preventDefault();
        Init();
        //Toast.show( 'Módosítások elvetése sikeres.', 'success' );
    });
    $(document).on('submit', '#profile #formProfile', function (e) {
        e.preventDefault();

        let langfields = [
            "name", "first_name", "last_name"
        ];

        var fdata = $(this).serializeObject();

        for (var i in fdata) {
            if (langfields.indexOf(i) > -1) {
                var newvalue = {};
                newvalue[localState.lang] = fdata[i];
                fdata[i] = newvalue;
            }
        }

        var valuesArray = {},
            rxp = /\[([^}]+)\]/g,
            curMatch;
        $.each(fdata, function(e) {
            console.log("e", e);
            if(e.indexOf("valuestr[") > -1) {
                while(curMatch = rxp.exec(e)) {
                    valuesArray[curMatch[1]] = {};
                    valuesArray[curMatch[1]][localState.lang] = fdata[e];
                    delete fdata[e];
                }
            }
            if(e.indexOf("values[") > -1) {
                while(curMatch = rxp.exec(e)) {
                    valuesArray[curMatch[1]] = fdata[e];
                    delete fdata[e];
                }
            }
        });
        console.log("valuesArray", valuesArray);
        fdata['valuesArray'] = valuesArray;

        fdata["lang"] = localState.lang;
        fdata["gdpr"] = AppData.profile.gdpr;

        writeDbLog("save profile", "", "user_logs", "users");
        $.post('api/', {
            do: 'saveProfile',
            data: fdata
        }, function (data) {
            AppData.profile = data.profile;
            Settings.Init();
            //Attendees.BuildFromSource();
            $('#hamburger').find('.data-user').text(AppData.profile.name[localState.lang]);

            Attendees.updateUser({udata: data.udata, id: AppData.profile.id});

            Socket.Send('Attendees.updateUser', {udata: data.udata, id: AppData.profile.id}, {
                client: 'client'
            });
            Socket.Send('Users.BuildFromSource', true, {
                client: 'admin'
            });
            //Toast.show( 'Adatlap mentése sikeres.', 'success' );
        });
        Layout.hide('profile');
    });
    $(document).on('focusin focusout', '#profile input', function (e) {
        if (e.type === 'focusout') {
            this.blur();
        }
        $(this).closest('fieldset').toggleClass('focus', e.type === 'focusin');
    });

    function update() {
        $.post('api/', {
            do: 'getProfile'
        }, function (data) {
            AppData.profile = data.profile;
            Qrcode.render();
            Roomlist.render();

            AppData.user_fields = data.user_fields;
            Init();

            data = null;
        });
    }

    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        DeleteProfilePictures: DeleteProfilePictures,
        buildLanguages: buildLanguages,
        update: update
    };
})();

var Gallery = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        $templateGalleryItem,
        $templateGalleryImageItem;

    localState.gallery = localState.gallery || {};
    localState.gallery.itemIndex = localState.gallery.itemIndex || 0;

    /* ----- Init Surveys --------------------------------------------------------------------------------------------- */
    function Init() {
        $templateGalleryItem = $('template#tplGalleryItem').remove().contents();
        $templateGalleryImageItem = $('template#tplGalleryImageItem').remove().contents();

        renderList();
        renderDetails();
        // AppData.
        delete Gallery.Init;
    }

    function sortGallery(data) {
        data.sort(function(a,b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        })
    }
    /* ----- Build Attendees List -------------------------------------------------------------------------------------- */
    function renderList() {

        var
            $self = $('#gallery #galleryList'),
            $fragment = $(document.createDocumentFragment()),
            data = AppData.gallery.filter(function (el) {
                return el.visible === true;
            });
        sortGallery(data);
        $.each(data, function (i, el) {
            var $clone = $templateGalleryItem.clone();
            $clone.attr('data-index', el.id);
            $clone.find('.data-title').html(el.title[localState.lang]);
            $clone.appendTo($fragment);
        });
        $self.html($fragment);
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails() {

        var
            $details = $('#gallery-details article'),
            $header = $('#gallery-details .data-label'),
            $fragmentDetails = $(document.createDocumentFragment()),
            $clone,
            $cloneItem,
            el = AppData.gallery.find(function (el) {
                return el.id === localState.gallery.itemIndex;
            }) || AppData.gallery[0];

        if (!el) return false;

        // $header.text(el.title[localState.lang]);
        $header.text(AppData.languages[AppData.settings.lang]['nav-gallery']);
        $details.find('.gallery_title h3').html(el.title[localState.lang]);

        if(el.description) {
            $details.find('.gallery_description').html(el.description[localState.lang]).show();
        }

        var user_votes = 0;

        $.each(el.images, function (i, imgel) {

            if ((AppData.modulsettings.gallery_moderate && imgel.moderated) || !AppData.modulsettings.gallery_moderate) {
                var $clone = $templateGalleryImageItem.clone();
                $clone.attr('data-index', imgel.id);
                $clone.find('.gallery_image').css({
                    "background-image": "url('upload/user_photos/" + imgel.filename + "')"
                });
                $clone.attr('data-photo_path', 'upload/user_photos/'+imgel.filename);
                if (AppData.modulsettings.gallery_userscanlike) {
                    $clone.find(".bottom").removeClass('hidden');
                    $clone.find(".gallery_image").removeClass('no_bottom');
                    $clone.find(".vote_btn").html(Language.getItem("gallery-vote"));
                    if (imgel.votes != undefined) {
                        var voted = $.inArray(AppData.profile.id, imgel.votes) > -1;
                        if (voted) {
                            user_votes++;
                        }
                        if (voted && !AppData.modulsettings.gallery_userscanrevoke) {
                            $clone.find(".vote_btn").addClass("disabled");
                        }
                        if (AppData.modulsettings.gallery_userscanrevoke && voted) {
                            $clone.find(".vote_btn").addClass("revoke").html(Language.getItem("gallery-revoke"));
                        }
                        $clone.find(".vote-count").html(imgel.votes.length);
                    }
                } else {
                    $clone.find(".bottom").addClass('hidden');
                    $clone.find(".gallery_image").addClass('no_bottom');
                }
                $clone.appendTo($fragmentDetails);
            }
        });

        $details.find('.items').html($fragmentDetails);

        var max_votes = parseInt(AppData.modulsettings.gallery_maxlikeperuser);

        if (user_votes >= max_votes && max_votes > 0) {
            $("#gallery-details .vote_btn").not(".revoke").addClass("disabled");
        }

        $clone = null;
    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() {
        $.post('api/', {
            do: 'getGallery'
        }, function (data) {
            AppData.gallery = data;
            renderList();
            renderDetails();
            data = null;
        })
    }

    function viewImage() {
        var parent = $(this).closest($(".item"));

        var imgviewer = $('<div/>').addClass("imageviewer");
        var bigimg = $('<img/>').attr("src", parent.attr("data-photo_path"));

        imgviewer.on("mouseup touchend", function (e) {
            e.preventDefault();
            imgviewer.fadeOut(400, function () {
                imgviewer.remove();
            });
        });

        imgviewer.append(bigimg);
        imgviewer.appendTo($("body"));
        imgviewer.fadeIn(400);
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        localState.gallery.itemIndex = String($(this).data('index')) || 0;
        renderDetails();
        Layout.show('gallery-details');
    }

    function showDetails(id) {
        if (id) {
            if (id !== localState.gallery.itemIndex || localState.gallery.itemIndex === 0) {
                localState.gallery.itemIndex = String(id);
                renderDetails();
            }
            Layout.show('gallery-details');
        }
    }

    function voteClick() {

        var id = $(this).closest('.item').attr("data-index");
        $(this).addClass('disabled');

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'votePhoto',
                id: id
            },
            success: function (data) {
                AppData.gallery = data;
                renderList();
                renderDetails();
                Socket.Send("Gallery.BuildFromSource", null);
                data = null;
            }
        });
    }

    /* ----- Bind Events -------------------------------------------------------------------------------------------- */
    $(document)
        .on('click', '#gallery-details .items .gallery_image', viewImage)
        .on('click', '#gallery #galleryList .item', evtClick)
        .on('click', '#gallery-details .vote_btn', voteClick);
    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showDetails: showDetails,
        renderDetails: renderDetails,
        evtClickDetails: evtClick
    };
})();

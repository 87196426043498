/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Roomlist = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {

        $(document).on('click', "#roomlist .vstagebtn", function (){
            let tc = $(this).attr("data-room");
            let ch = $(this).attr("data-channel");

            if (ch != undefined && ch != "undefined" && ch != "") {
                Channels.setChannel(ch);
            }

            openTrueConfCall(tc, 'workshop');
            if ($("#landing .navplusbutton i").hasClass("fa-minus")) {
                $("#landing .navplusbutton .open-main").trigger("mouseup");
            }
        });

        $(document).on('click', "#roomlist .bcbtn", function (){
            let bc = $(this).attr("data-broadcast");
            let ch = $(this).attr("data-channel");

            if (ch != undefined && ch != "undefined" && ch != "") {
                Channels.setChannel(ch);
            }

            Layout.hideAll();
            Broadcast.playStreamByURL("https://"+bc);
            if ($("#landing .navplusbutton i").hasClass("fa-minus")) {
                $("#landing .navplusbutton .open-main").trigger("mouseup");
            }
        });

        $(document).on('click', "#roomlist .external_link", function (){
            let url = $(this).attr("data-url");
            let ch = $(this).attr("data-channel");

            if (ch != undefined && ch != "undefined" && ch != "") {
                Channels.setChannel(ch);
            }

            window.open(url);
        });

        render();
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function render() {
        if (AppData.profile.roomlist != "") {
            let text = AppData.profile.roomlist;
            //<button class="btn" onclick="openTrueConfCall('meeting2.rentit.hu/c/test_public')">TESZT</button>
            //#1;TESZT;meeting2.rentit.hu/c/test_public;ch1#

            let myRegexp = /#([A-Za-z0-9áéúőóüöíű ;:.,?!\/%_=\-]*)#/gi;
            let match = myRegexp.exec(text);
            while (match != null) {
                let splitted = match[1].split(";");
                if (splitted.length == 4) {
                    if (splitted[0] == "1") {
                        text = text.replace(match[0], '<button class="btn bcbtn" data-broadcast="' + splitted[2].trim() + '" data-channel="'+splitted[3]+'">' + splitted[1].trim() + '</button>');
                    } else if (splitted[0] == "2") {
                        text = text.replace(match[0], '<button class="btn vstagebtn" data-room="' + splitted[2].trim() + '" data-channel="'+splitted[3]+'">' + splitted[1].trim() + '</button>');
                    } else {
                        text = text.replace(match[0], '<button class="btn external_link" data-url="' + splitted[2].trim() + '" data-channel="'+splitted[3]+'">' + splitted[1].trim() + '</button>');
                    }
                }
                match = myRegexp.exec(text);
            }

            $("#roomlist .item").html(text);
        }
    }

    function update() {
        $.post('api/', {
            do: 'getProfile'
        }, function (data) {
            AppData.profile = data.profile;
            Roomlist.render();
            data = null;
        });
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        render: render,
        update: update
    }
})();

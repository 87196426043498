var Gdpr = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        render();
        $(document).on("click", "#gdpr .accept-gdpr", acceptGdpr);
        $(document).on("click", "#gdpr .doc-link", openGdpr);

        if (AppData.modulsettings.showgdpr && (AppData.profile.gdpr == undefined || !AppData.profile.gdpr)) {
            Layout.show("gdpr", true);
        }
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function render() {
        $('#gdpr').find('.data-text').html(nl2brStrict(AppData.gdpr.gdpr[localState.lang]));

        var label_text = AppData.gdpr.checkbox_text[localState.lang];
        if (AppData.gdpr.checkbox_text[localState.lang] != undefined) {
            if (AppData.gdpr.linked_doc) {
                label_text = label_text.replace("[link]", '<a href="javascript:void(0);" class="color-primary doc-link" data-target="' + AppData.gdpr.linked_doc + '">');
                label_text = label_text.replace("[/link]", '</a>');
            } else {
                label_text = label_text.replace("[link]", '');
                label_text = label_text.replace("[/link]", '');
            }
        }

        var add_label_text = AppData.gdpr.add_checkbox_text[localState.lang];

        $('#gdpr').find('.chk-label').html(label_text);
        $('#gdpr').find('.chk-label-add').html(add_label_text);

        $('#gdpr').find('.chk-label-add').hide();

        if ($.inArray(AppData.profile.group, AppData.gdpr.group) > -1) {
            $('#gdpr').find('.chk-label-add').show();
        }

        $('#gdpr').find('.accept-gdpr').html(AppData.gdpr.button_text[localState.lang]);
        $('#gdpr').find('header .close-main').hide();
        $('#gdpr').find('header .hamburger').hide();
        $('#gdpr').find('header .data-icon').hide();
        $('#gdpr').find('header').css({
            "pointer-events": "none"
        });

        $('#gdpr').find('header .data-label').html(Language.getItem("gdpr-title"));
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function update() {
        $.post('api/', {
            do: 'getGdpr'
        }, function (data) {
            AppData.gdpr = data;
            Gdpr.render();
            data = null;
        });
    }

    function openGdpr(e) {
        var id = $(this).attr("data-target");
        var el = AppData.docs.find(function(item) {
            return item.id == id;
        });

        $('#gdpr-docs').find('header .hamburger').hide();

        $('#gdpr-docs').find('header .header-primary').css({
            "pointer-events": "none"
        });

        $('#gdpr-docs .data-label').text(el.title[localState.lang]);
        $('#gdpr-docs embed').attr('src', './pdfjs/web/viewer.html?file=../../upload/docs/' + el.files[localState.lang].filename);
        Layout.show("gdpr-docs");

        var index = localState.layout.indexOf("gdpr-docs");
        if (index > -1) {
            localState.layout.splice(index, 1);
        }
    }

    function acceptGdpr() {

        if (!$("#gdpr .accept-gdpr-chk").prop("checked")) {
            $('#modal').css({
                "z-index": 10000000
            });
            Modal.alert(AppData.gdpr.alert_text[localState.lang], function() {
                $('#modal').css({
                    "z-index": ""
                });
            });
            return;
        }

        if (AppData.gdpr.add_chk_mandatory && !$("#gdpr .accept-gdpr-chk-add").prop("checked") && $.inArray(AppData.profile.group, AppData.gdpr.group) > -1) {
            $('#modal').css({
                "z-index": 10000000
            });
            Modal.alert(AppData.gdpr.add_alert_text[localState.lang], function() {
                $('#modal').css({
                    "z-index": ""
                });
            });
            return;
        }

        $.post('api/', {
            do: 'acceptGdpr',
            addChk: $("#gdpr .accept-gdpr-chk-add").prop("checked")
        }, function (data) {
            AppData.profile = data;
            Layout.hide("gdpr");
            Navigation.setAllowCloseMenu();
        });

    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        render: render,
        update: update
    }
})();

/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
  */
var Docs = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateDocsItem;
    var docs;
    var actType = "";
    let last_groupfilter;

    /* ----- Init Agenda ---------------------------------------------------------------------------------------------- */
    function Init() {
        $templateDocsItem = $('template#tplDocsItem').remove().contents();
        docs = AppData.docs.filter(function (el) {
            return el.active;
        });
        $(document)
            .on('input', '#docs .search-wrapper input', evtInput)
            .on('keyup', '#docs .search-wrapper input', evtPressEnter)
            .on('click', '#docs .search-wrapper .btn-clear', evtClear)
            .on('click', '#docs #docsList .item', evtClick);

        localState.docs = localState.docs || {};
        localState.docs.itemIndex = localState.docs.itemIndex || 0;

        renderList();
        renderDetails();
        $('#docs .search-wrapper input').val('');
        delete Docs.Init;
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortDocs() {
        docs.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function sortGroups() {
        AppData.docsgroups.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    /* ----- Build Docs List ---------------------------------------------------------------------------------------- */
    function renderList(groupfilter) {
        $('#docs').find(".search-wrapper").toggle(AppData.modulsettings.showsearchbar === true);
        sortDocs();
        var
            $self = $('#docs #docsList'),
            $fragmentDocs = $(document.createDocumentFragment());

        var nogroups = [];
        if (AppData.docsgroups.length === 0) {
            nogroups = AppData.docs;
        }

        sortGroups();

        var groups = AppData.docsgroups;
        if (groupfilter != undefined) {
            groups = AppData.docsgroups.filter(function (el) {
                // return $.inArray(el.id, groupfilter) > -1;
                return el.id === groupfilter.id;
            });
        }

        last_groupfilter = groupfilter;
        if (localState.docsgroups !== undefined && localState.docsgroups.itemIndex !== undefined) {
            groups = AppData.docsgroups.filter(function (el) {
                return el.id == localState.docsgroups.itemIndex;
            });
        }

        $.each(groups, function (gi, gel) {
            var in_group_count = 0;

            var grouptitle = $("<h3/>").addClass("grouptitle").css({
                color: gel.titlecolor || "#555555"
            }).html(gel.title[localState.lang]);
            if(groupfilter !== undefined || (localState.docsgroups !== undefined && localState.docsgroups.itemIndex !== undefined)) {
                $('#docs .data-label').html(gel.title[localState.lang]);
            }
            grouptitle.appendTo($fragmentDocs);
            $.each(docs, function (i, el) {
                var inarray = nogroups.find(function (ng) {
                    return ng.id == el.id;
                });
                if ((el.group == "" || el.group == undefined) && inarray == undefined) {
                    nogroups.push(el);
                }

                if (el.group == gel.id && el.active) {
                    in_group_count++;

                    var $clone = $templateDocsItem.clone();
                    $clone.attr('data-index', el.id);
                    $clone.find('.data-title').text(el.title[localState.lang]);
                    if (el.tags != undefined && el.tags[AppData.settings.lang] != undefined && el.tags[AppData.settings.lang] != "" && AppData.modulsettings.showtags) {
                        var buffer = el.tags[AppData.settings.lang].split(',');
                        var buffer2 = [];
                        $.each(buffer, function (i) {
                            var tag = $.trim(this);
                            buffer2.push("<span class='data-tags-item item-" + el.id + '-' + i + "' data-tag='" + tag + "'>#" + tag.toLowerCase() + '</span>');
                        });
                        $clone.find('.data-tags').html(buffer2.join(' ')).css('display', 'block');
                    }
                    $clone.appendTo($fragmentDocs);
                }
            });

            if (in_group_count == 0) {
                grouptitle.remove();
            }
        });

        if (nogroups.length > 0 && groups.length === AppData.docsgroups.length) {
            var grouptitle = $("<h3/>").addClass("grouptitle").html("&nbsp;");
            grouptitle.appendTo($fragmentDocs);

            $.each(nogroups, function (i, el) {

                if (el.active) {
                    var $clone = $templateDocsItem.clone();
                    $clone.attr('data-index', el.id);
                    $clone.find('.data-title').text(el.title[localState.lang]);
                    if (el.tags != undefined && el.tags[AppData.settings.lang] != undefined && el.tags[AppData.settings.lang] != "" && AppData.modulsettings.showtags) {
                        var buffer = el.tags[AppData.settings.lang].split(',');
                        var buffer2 = [];
                        $.each(buffer, function (i) {
                            var tag = $.trim(this);
                            buffer2.push("<span class='data-tags-item item-" + el.id + '-' + i + "' data-tag='" + tag + "'>#" + tag.toLowerCase() + '</span>');
                        });
                        $clone.find('.data-tags').html(buffer2.join(' ')).css('display', 'block');
                    }
                    $clone.appendTo($fragmentDocs);
                }
            });
        }
        $self.html($fragmentDocs);

        $.each(docs, function (i, el) {
            if(el.tags != undefined && AppData.modulsettings.showtags) {
                var count = el.tags[AppData.settings.lang].split(',').length;
                for (var c = 0; c < count; c++) {
                    $('.data-tags').find('.item-' + el.id + '-' + c ).on('click', function (e) {
                        e.stopPropagation();
                        var
                            $btnClear = $('#docs .search-wrapper .btn-clear'),
                            value = $('#docs .search-wrapper input').val("#" + this.dataset.tag.toLowerCase()),
                            search = this.dataset.tag.split(' ').join('').toLowerCase(),
                            regex = new RegExp('#' + strCanonical(search) + '#', 'mi');

                        requestAnimationFrame(function () {
                            $('#docs #docsList .item').each(function (i, el) {
                                $(el).toggle(regex.test(strCanonical($(el).find('.data-tags').text().split(' ').join('') + "#")));
                            });
                            $btnClear.toggle(Boolean(value.length));
                        });
                    })
                }
            }
        });
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails() {
        if ('docs' in localState && docs.length > 0) {
            var
                $header = $('#docs-details .data-label'),
                el = docs.find(function (el) {
                    return el.id == localState.docs.itemIndex
                });
            if (el) {
                writeDbLog("show document", el.id, "user_logs", "docs");
                $header.text(el.title[localState.lang]);
                if (el.files[localState.lang]) {
                    $('#docs-details embed').attr('src', './pdfjs/web/viewer.html?file=../../upload/docs/' + el.files[localState.lang].filename);
                }
            }
            //$('#docs-details embed').attr('src', './ViewerJS/#../upload/docs/' + el.files[localState.lang].filename);
        }
    }

    /* ----- Event: Input on Search ----------------------------------------------------------------------------------- */
    function evtInput(e) {
        var
            isResult = false,
            $btnClear = $('#docs .search-wrapper .btn-clear'),
            $noResult = $('#docs .noresults'),

            value = $('#docs .search-wrapper input').val(),
            search = value.split(' ').join(''),
            regex1 = new RegExp(strCanonical(search), 'i'),
            regex2 = new RegExp(strCanonical(search), 'i');

        requestAnimationFrame(function () {
            $('#docs #docsList .item').each(function (i, el) {
                var
                    str1 = $(el).find('.data-title').text().split(' ').join(''),
                    str2 = $(el).find('.data-tags').text().split(' ').join(''),

                    isMatching =
                        regex1.test(strCanonical(str1)) ||
                        regex2.test(strCanonical(str2));

                $(el).toggle(isMatching);
                isResult = (isMatching) ? true : isResult;
                str1 = isMatching = null;
            });
            $btnClear.toggle(Boolean(value.length));
            $noResult.toggle(!isResult);
            isResult = $btnClear = $noResult = value = search = regex1 = null;
        });
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        localState.docs = {
            itemIndex: String($(this).data('index')) || 0
        };
        renderDetails();
        Layout.show('docs-details');
    }


    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClear(e) {
        e.preventDefault();
        var $input = $('#docs .search-wrapper input');
        $input.val('').trigger('input');
    }

    /* ----- Event: Press Enter --------------------------------------------------------------------------------------- */
    function evtPressEnter(e) {
        if (e.wich || e.keyCode === 13) {
            this.blur();
        }
    }

    function showDetails(id, call_show) {
        if (id) {

            if (id === "agendadoc") {
                docs = AppData.docs.filter(function (el) {
                    return el.type === id && el.active;
                });
                localState.docs.itemIndex = docs[0].id;
                renderDetails();
                Layout.show('docs-details');
            } else if (id === "all_docs") {
                docs = AppData.docs.filter(function (el) {
                    return el.type === id && el.active;
                });
                renderList();
                Layout.show('docs');
            } else {
                let docstest = AppData.docs.filter(function (el) {
                    return el.id === id;
                });
                if(docstest.length > 0 && docstest[0].group !== undefined) {
                    if (localState.docs != undefined) {
                        if (id !== localState.docs.itemIndex || localState.docs.itemIndex === 0) {
                            localState.docs.itemIndex = String(id);
                        }
                    } else {
                        localState.docs = {'itemIndex': String(id)};
                    }

                    renderDetails();

                    if (call_show == undefined || call_show == true) {
                        Layout.show('docs-details');
                    }
                } else {
                    var groups = AppData.docsgroups.find(function (el) { // van megadott group, akkor doksi lista kell belőle
                        return id == el.id;
                    });
                    if (localState.docsgroups !== undefined) {
                        if (id !== localState.docsgroups.itemIndex || localState.docsgroups.itemIndex === 0) {
                            localState.docsgroups.itemIndex = String(id);
                        }
                    } else {
                        localState.docsgroups = {'itemIndex': String(id)};
                    }

                    if (groups != undefined) {
                        renderList(groups);
                        Layout.show("docs");
                    }
                }
            }
        }
    }

    function showList(id) {
        if (id == "") {
            actType = "";
            delete localState.docsgroups.itemIndex;
            docs = AppData.docs.filter(function (el) {
                return el.active;
            });
            renderList();
        } else if (id == "docs" || id == "useful") {
            actType = id;
            docs = AppData.docs.filter(function (el) {
                return el.type == id && el.active;
            });
            renderList();
        }


    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() { // group mentés frissítés?
        $.post('api/', {
            do: 'getDocs'
        }, function (data) {
            AppData.docs = data;
            sortDocs();
            docs = AppData.docs.filter(function (el) {
                return el.active;
            });

            if(last_groupfilter !== undefined || (localState.docsgroups !== undefined && localState.docsgroups.itemIndex !== undefined)) {
                renderList(last_groupfilter);
            } else {
                showList(actType);
            }

            Agenda.renderList();

            data = null;
        })
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showDetails:showDetails,
        showList:showList,
        renderList:renderList,
        evtClickDetails: evtClick
    };
})();

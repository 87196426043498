var Moderate = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateModerateItem, filter, user_rooms;
    var current_texts = {};
    var answer_texts = {};
    var sendToUser = false;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateModerateItem = $('template#tplModerateItem').remove().contents();
        filter = 'all';
        user_rooms = AppData.profile.rooms || '';
        $('.filter-all').addClass('bg-primary');
        renderList();
        delete Moderate.Init;
    }

    /* ----- Build Docs List ---------------------------------------------------------------------------------------- */
    function renderList() {
        var
            $self = $('#moderate .moderateList, #landing .landing_elements .moderate_cont .moderateList'),
            $fragmentNotes = $(document.createDocumentFragment());
        var issues_to_moderate = AppData.issues.filter(function (el) {
            el.active = (el.active === undefined) ? false : el.active;
            if (el.room_id && el.active) {
                switch (filter) {
                    case 'all':
                        // return true;
                        return (user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms || el.room_id === 'all'));
                        break;
                    case 'approved':
                        // return el.approved;
                        return (user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms || el.room_id === 'all') && el.approved);
                        break;
                    case 'denied':
                        // return el.approved === false;
                        return (user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms || el.room_id === 'all') && el.approved === false);
                        break;
                    case 'waiting':
                        // return el.approved === undefined;
                        return (user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms || el.room_id === 'all') && el.approved === undefined);
                        break;
                }
            }
            return false;
        });

        if (AppData.modulsettings.qandausercanvote) {
            issues_to_moderate.sort(function (a, b) {
                let aup = 0;
                let adown = 0;

                if (a.upvotes) {
                    aup = a.upvotes.length;
                }

                if (a.downvotes) {
                    adown = a.downvotes.length;
                }

                let bup = 0;
                let bdown = 0;

                if (b.upvotes) {
                    bup = b.upvotes.length;
                }

                if (b.downvotes) {
                    bdown = b.downvotes.length;
                }

                let avotes = aup - adown;
                let bvotes = bup - bdown;

                return bvotes - avotes;
            });
        }
        $.each(issues_to_moderate, function (i, el) {
            var $clone = $templateModerateItem.clone();
            $clone.attr('data-index', el.id);
            var speaker = AppData.speakers.find(function (s) {
                return s.id === el.speaker;
            });

            /*var from = AppData.attendees.find(function (s) {
                return s.id === el.user;
            });*/

            var from = el.username[localState.lang];

            var agenda = AppData.agenda.find(function (a) {
                return a.id === el.agenda;
            });

            var qtext = "";

            if (AppData.modulsettings.qandashowprogram) {
                qtext += agenda ? "Agenda: " + agenda.title[localState.lang] + '<br>' : "";
            }
            qtext += speaker ? "To: " + speaker.name[localState.lang] + '<br>' : "";
            qtext += from ? "From: " + (el.is_anonym ? "Anonymous" : from) + '<br>' : "";
            //qtext += el.question;

            $clone.find('.data-sender').html(qtext);
            $clone.find('.data-question').html(el.question.replace(/(\r\n|\n)/gi, '<br>'));
            $clone.find('.data-note').html(el.note ? el.note.replace(/(\r\n|\n)/gi, '<br>') : "");
            $clone.find('.data-answer').html(el.answer ? el.answer.replace(/(\r\n|\n)/gi, '<br>') : "");

            if (el.answer) {
                $clone.find('.answer_cont').removeClass("hidden");
            } else {
                $clone.find('.answer_cont').addClass("hidden");
            }
            if (el.note) {
                $clone.find('.note_cont').removeClass("hidden");
            } else {
                $clone.find('.note_cont').addClass("hidden");
            }

            if (AppData.modulsettings.answer_enabled_for_moderator) {
                $(".edit_question").find('.answer_cont_edit').removeClass("hidden");
            } else {
                $(".edit_question").find('.answer_cont_edit').addClass("hidden");
            }
            if (AppData.modulsettings.note_enabled_for_moderator) {
                $(".edit_question").find('.note_cont_edit').removeClass("hidden");
            } else {
                $(".edit_question").find('.note_cont_edit').addClass("hidden");
            }

            $clone.appendTo($fragmentNotes);
            if (el.approved) {
                $clone.find('.approve-btn').addClass('hidden');
                $clone.addClass('approved');
                $clone.children('i').first().removeClass('fa-question').addClass('fa-check');
            }
            if (el.approved === false) {
                $clone.find('.deny-btn').addClass('hidden');
                $clone.addClass('denied');
                $clone.children('i').first().removeClass('fa-question').addClass('fa-times');
            }

            let upvotes = 0;
            let downvotes = 0;

            if (el.upvotes) {
                upvotes = el.upvotes.length;
            }

            if (el.downvotes) {
                downvotes = el.downvotes.length;
            }
            if (AppData.modulsettings.qandausercanvote) {
                $clone.find(".vote-num").html(upvotes - downvotes);
            }
            //TODO: temporary return button hide
            if (el.approved !== undefined || true) {
                $clone.find('.return-btn').addClass('hidden');
            }
        });
        $self.html($fragmentNotes);
        var approve_btn_text = Language.getItem('moderate-approve');
        $('.moderate-approve').text(approve_btn_text);
        var deny_btn_text = Language.getItem('moderate-deny');
        $('.moderate-deny').text(deny_btn_text);
        var return_btn_text = Language.getItem('moderate-return');
        $('.moderate-return').text(return_btn_text);
        $('#moderate, #landing .landing_elements .moderate_cont').find('.no-result').toggle(Boolean(!AppData.issues.length));
    }

    function evtApproveClick() {
        var issue_id = $(this).closest('.item').attr('data-index');
        //$(this).prop('disabled', true);

        $.post('api/', {
            do: 'approveIssues',
            data: {
                id: issue_id,
                approved: true,
                client_approved: true
            }
        }, function (data) {
            AppData.issues = data.issues;
            current_texts[issue_id] = "";
            answer_texts[issue_id] = "";
            renderList();

            /*Socket.Send('Issues.BuildFromSource', true);
            Socket.Send('Issues.BuildFromSource', true, {
                client: 'issues'
            });*/

            //Socket.Send('Stage.BuildFromSource', true);
            //Socket.Send('Moderate.BuildFromSource', true);

            Socket.Send("refreshModule", {
                module: ['Issues', 'Stage', 'Moderate'],
                appdata: 'issues',
                data: data.issues,
                callback: ['Build', 'renderList', 'renderList']
            }, {client: ['issues']});

            Socket.Send("refreshModule", {
                module: ['Issues', 'Stage', 'Moderate'],
                appdata: 'issues',
                data: data.issues,
                callback: ['Build', 'renderList', 'renderList']
            }, {group: ['speaker', 'moderator', 'director']});

            if (data.user != 0 && sendToUser) {
                Socket.Send("refreshModule", {
                    module: ['Issues', 'Stage', 'Moderate'],
                    appdata: 'issues',
                    data: data.issues,
                    callback: ['Build', 'renderList', 'renderList']
                }, {"uid": data.user});
            }

            Stage.Build();
        })
    }

    function evtDenyClick() {
        var issue_id = $(this).closest('.item').attr('data-index');
        //$(this).prop('disabled', true);
        $.post('api/', {
            do: 'approveIssues',
            data: {
                id: issue_id,
                approved: false
            }
        }, function (data) {
            AppData.issues = data.issues;
            current_texts[issue_id] = "";
            answer_texts[issue_id] = "";
            renderList();

            /*Socket.Send('Issues.BuildFromSource', true);

            Socket.Send('Issues.BuildFromSource', true, {
                client: 'issues'
            });*/
            //Socket.Send('Stage.BuildFromSource', true);
            //Socket.Send('Moderate.BuildFromSource', true);

            Socket.Send("refreshModule", {
                module: ['Issues', 'Stage', 'Moderate'],
                appdata: 'issues',
                data: data.issues,
                callback: ['Build', 'renderList', 'renderList']
            }, {client: ['issues']});

            Socket.Send("refreshModule", {
                module: ['Issues', 'Stage', 'Moderate'],
                appdata: 'issues',
                data: data.issues,
                callback: ['Build', 'renderList', 'renderList']
            }, {group: ['speaker', 'moderator', 'director']});

            if (data.user != 0 && sendToUser) {
                Socket.Send("refreshModule", {
                    module: ['Issues', 'Stage', 'Moderate'],
                    appdata: 'issues',
                    data: data.issues,
                    callback: ['Build', 'renderList', 'renderList']
                }, {"uid": data.user});
            }

            Stage.Build();
        })
    }

    function evtReturnClick() {
        var issue_id = $(this).closest('.item').attr('data-index');
        //$(this).prop('disabled', true);
        $.post('api/', {
            do: 'returnIssues',
            data: {
                id: issue_id
            }
        }, function (data) {
            AppData.issues = data;
            renderList();
            Socket.Send('Issues.BuildFromSource', true, {
                group: 'admin'
            });

            //Socket.Send('Moderate.BuildFromSource', true);

            Socket.Send("refreshModule", {
                module: ['Moderate'],
                appdata: 'issues',
                data: data,
                callback: ['renderList']
            }, {client: ['client']});
        })
    }

    function evtFilterClick(e) {
        $("button[class*='filter']").removeClass('bg-primary');
        $(this).addClass('bg-primary');
        filter = e.data.filter;
        renderList();
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getIssues'
        }, function (data) {
            AppData.issues = data;
            renderList();
        });
    }

    function UpdateModule(data) {
        AppData.issues = data;
        renderList();
    }

    function notify(data) {
        if (user_rooms.indexOf(data.room_id) > -1) {
            Notify.show(data);
        }
    }

    function editMessage() {

        var parent = $(this).closest('.item');
        var issue_id = parent.attr('data-index');

        $.ajax({
            url: 'api/',
            method: 'post',
            data: {
                do: 'getIssue',
                id: issue_id
            },
            success: function (response) {
                if (response.id) {
                    $(".edit_question .item").attr("data-index", response.id);
                    $(".edit_question [name=moderated_issue_id]").val(response.id);
                    $(".edit_question .issue_msg").val(response.question ? response.question.replace(/<br>/gi, '\n') : "");
                    $(".edit_question .issue_answer_msg").val(response.answer ? response.answer.replace(/<br>/gi, '\n') : "");
                    $(".edit_question .issue_note_msg").val(response.note ? response.note.replace(/<br>/gi, '\n') : "");
                    $(".edit_question").show();
                }
            }
        });
    }

    function cancelModMessage() {
        $(".edit_question").hide();
    }

    function saveModMessage() {
        var parent = $(this).closest('.item');
        var issue_id = parent.attr('data-index');

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'saveModeratedIssue',
                id: issue_id,
                moderated_text: $(".edit_question .issue_msg").val(),
                answer_text: $(".edit_question .issue_answer_msg").val(),
                note_text: $(".edit_question .issue_note_msg").val()
            },
            success: function (resp) {
                $(".edit_question").hide();

                AppData.issues = resp.issues;
                renderList();

                //BuildFromSource();
                /*Socket.Send('Issues.BuildFromSource', true);
                Socket.Send('Stage.BuildFromSource', true);
                Socket.Send('Issues.BuildFromSource', true, {
                    client: 'issues'
                });
                Socket.Send('Moderate.BuildFromSource', true);*/

                Socket.Send("refreshModule", {
                    module: ['Issues', 'Stage', 'Moderate'],
                    appdata: 'issues',
                    data: resp.issues,
                    callback: ['Build', 'renderList', 'renderList']
                }, {client: ['issues']});

                Socket.Send("refreshModule", {
                    module: ['Issues', 'Stage', 'Moderate'],
                    appdata: 'issues',
                    data: resp.issues,
                    callback: ['Build', 'renderList', 'renderList']
                }, {group: ['speaker', 'moderator', 'director']});

                if (data.user != 0 && sendToUser) {
                    Socket.Send("refreshModule", {
                        module: ['Issues', 'Stage', 'Moderate'],
                        appdata: 'issues',
                        data: resp.issues,
                        callback: ['Build', 'renderList', 'renderList']
                    }, {"uid": data.user});
                }


            },
            error: function () {
            }
        });
    }

    $(document)
        .on('click', '#moderate .item .edit_btn, #landing .landing_elements .moderate_cont .item .edit_btn', editMessage)
        .on('click', '.edit_question .item .save_issue_mod_btn', saveModMessage)
        .on('click', '.edit_question .item .cancel_issue_mod_btn', cancelModMessage)
        .on('click', '.edit_question .item .approve_mod_btn', evtApproveClick)
        .on('click', '#moderate .item .approve-btn, #landing .landing_elements .moderate_cont .item .approve-btn', evtApproveClick)
        .on('click', '#moderate .item .deny-btn, #landing .landing_elements .moderate_cont .item .deny-btn', evtDenyClick)
        .on('click', '#moderate .item .return-btn, #landing .landing_elements .moderate_cont .item .return-btn', evtReturnClick)
        .on('click', '#moderate .filter-all, #landing .landing_elements .moderate_cont .filter-all', {filter: 'all'}, evtFilterClick)
        .on('click', '#moderate .filter-approved, #landing .landing_elements .moderate_cont .filter-approved', {filter: 'approved'}, evtFilterClick)
        .on('click', '#moderate .filter-waiting, #landing .landing_elements .moderate_cont .filter-waiting', {filter: 'waiting'}, evtFilterClick)
        .on('click', '#moderate .filter-denied, #landing .landing_elements .moderate_cont .filter-denied', {filter: 'denied'}, evtFilterClick);
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        renderList: renderList,
        UpdateModule: UpdateModule,
        notify: notify
    }
})();

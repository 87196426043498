var Trueconf = (function () {

    var $templateTrueconfItem;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateTrueconfItem = $('template#tplTrueconfItem').contents(); // remove().
        Build();
    }

    function sort() {
        AppData.trueconf.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function Build() {
        var $fragment = $(document.createDocumentFragment()),
            $clone = $templateTrueconfItem.clone();

        sort();

        $.each(AppData.trueconf, function (i, el) {
            if (el.visible != undefined && el.visible) {
                $clone = $templateTrueconfItem.clone();
                $clone.attr("data-id", el.id).attr("data-room", el.trueconf_room)
                $clone.find('.data-name').html(el.title[AppData.settings.lang]);
                $clone.appendTo($fragment);
            }
        });
        $('#trueconf #trueconfList').html($fragment);
    }

    $(document).on('click', '#trueconfList .item', function (e) {
        showDetails($(this).attr("data-id"));
    });

    function showDetails(id) {
        if (id) {

            var tcroom;
            var server;
            var channel;
            let translator = false;

            if (id == "exhibitor") {
                tcroom = AppData.profile.trueconf_room;
                server = 'exhibitor';

                if (AppData.profile.group == "director" || AppData.profile.group == "moderator") {
                    server = 'workshop';
                }

                if (AppData.profile.group == "translator" && AppData.modulsettings.translator_use_relay) {
                    translator = true;
                }
            } else {
                var trueconf_item = AppData.trueconf.find(function (e) {
                    return e.id == id;
                });

                server = 'workshop';
                tcroom = trueconf_item.trueconf_room;
                channel = trueconf_item.channel;
            }
            if (translator) {
                if (trueconf_translator_opened) {
                    closeTrueConfCall("translator");
                } else {
                    closeTrueConfCall("translator");
                    openTrueConfCall(tcroom, server, channel,false, translator);
                }
            } else {
                if (trueconf_opened) {
                    closeTrueConfCall("default");
                } else {
                    closeTrueConfCall("default");
                    openTrueConfCall(tcroom, server, channel);
                }
            }

            /*if (trueconf_opened) {
                closeTrueConfCall();
                $(".mute-video-button").show().removeClass("animated fadeOut").addClass("animated fadeIn");
                $("#default_nav").find("[data-target='trueconf']").css("border", '');
            } else {
                closeTrueConfCall();
                openTrueConfCall(tcroom, server, false, translator);
                $("#default_nav").find("[data-targetid='" + id + "']").css("border", '2px solid ' + AppData.settings.colorIconBorder);
            }*/
        }
    }

    $(document).on('click', ".landing_elements .trueconf .translator_btn", function (){
        Layout.show("soundchannels");
    });

    $(document).on('click', ".landing_elements .trueconf .closetrueconf_btn", function (){
        closeTrueConfCall("default");
    });

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getTrueconf'
        }, function (data) {
            AppData.trueconf = data;
            Init();
        });
    }

    function pushTcRoom(room) {
        closeTrueConfCall();
        openTrueConfCall(room, 'workshop');
    }

    function showTranslator() {
        if (AppData.modulsettings.showtransbutton_trueconf) {
            $('#landing .landing_elements .trueconf .translator_btn').show();
        } else {
            $('#landing .landing_elements .trueconf .translator_btn').hide();
        }
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        BuildBroadcast: Build,
        showDetails: showDetails,
        showTranslator: showTranslator,
        pushTcRoom: pushTcRoom
    }
})();

var TranslatorRelay = (function () {

    let added_players = [];

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        added_players = [];
        $.each(AppData.translator, function (i, el) {
            if (el.visible != undefined && el.visible) {
                let publisher = new WebRTCPublisher(el);
                added_players.push(publisher);
            }
        });

        let floor = AppData.translator.find(function (item) {
            return item.is_floor;
        });

        if (floor) {
            let floorpublish = new WebRTCPublisher(floor);
            added_players.push(floorpublish);
        }
    }

    function startPublishing() {
        for (let i in added_players) {
            if (!added_players[i].initialized) {
                added_players[i].init();
                added_players[i].update(added_players[i].state.settings);
            }
        }
    }

    let saving = false;
    function setDefaultDevices() {
        if (!saving) {
            saving = true;

            let translator_inputs = [];

            $("#translator-relay .input-select").each(function (i, item) {
                translator_inputs.push({
                    translator_id: $(this).attr("data-translator"),
                    device_id: $(this).val()
                });
            });

            $.ajax({
                method: 'post',
                url: 'api/',
                data: {
                    do: 'setDefaultDevices',
                    data: translator_inputs
                },
                success: function (resp) {
                    AppData.translator = resp;
                    Modal.alert("Saved.");
                    saving = false;
                },
                error: function () {
                    Modal.alert("Error.");
                    saving = false;
                }
            });
        }
    }

    $(document).on("click", ".start-publishing", startPublishing);
    $(document).on("click", ".set-input-devices", setDefaultDevices);

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getTranslator'
        }, function (data) {
            AppData.translator = data;
        });
    }

    function changeInputs(data) {
        console.log(data);

        if (added_players.length > 0) {
            let main_player;
            let own_player;
            let other_players = [];
            for (let i in added_players) {
                if (added_players[i].is_main) {
                    main_player = added_players[i];
                } else if (added_players[i].translator_item.assigned_translator.indexOf(data.user_id) > -1) {
                    own_player = added_players[i];
                } else {
                    other_players.push(added_players[i]);
                }
            }
            if (data.main_lang == "true") {
                main_player.inputSelect.val(own_player.translator_item.deviceId);
                main_player.changeInput(own_player.translator_item.deviceId);
                own_player.inputSelect.val(main_player.translator_item.deviceId);
                own_player.changeInput(main_player.translator_item.deviceId);
            } else {
                main_player.inputSelect.val(main_player.translator_item.deviceId);
                main_player.changeInput(main_player.translator_item.deviceId);
                own_player.inputSelect.val(own_player.translator_item.deviceId);
                own_player.changeInput(own_player.translator_item.deviceId);
            }
            for (let o in other_players) {
                other_players[o].inputSelect.val(other_players[o].translator_item.deviceId);
                other_players[o].changeInput(other_players[o].translator_item.deviceId);
            }
        }
    }

    function showDetails(id) {

    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showDetails: showDetails,
        changeInputs: changeInputs
    }
})();

/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

var Points = (function () {
    'use strict'

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function getPoits() {
        $.post('api/', {
            do: 'getMyPoints'
        }, function (res) {
            $('#points .data-points').text(res);
            $('#points .results').text(res);
        });
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function sendExhibitionPoints(points) {
        if (Number(points) >= 0) {
            $.post('api/', {
                do: 'sendExhibitionPoints', points: Number(points)
            }, function (res) {
                $('#points .results').text(res);
            });
        }
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function sendQuizPoints(points) {
        if (points) {
            $.post('api/', {
                do: 'sendQuizPoints', points: Number(points)
            }, function (res) {
                $('#points .results').text(res);
            });
        }
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(document)
        .on('show', '#points', getPoits)
        .on('click', '#points .get', getPoits)
        .on('click', '#points .set-exhibition', function () {
            sendExhibitionPoints(200)
        })
        .on('click', '#points .set-quiz', function () {
            sendQuizPoints(100)
        });
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        sendExhibitionPoints: sendExhibitionPoints,
        sendQuizPoints: sendQuizPoints
    }
})();

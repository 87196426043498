/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var Toast = (function () {
    'user strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var
        timer,
        classListShow = 'animated faster fadeInDown active',
        classListHide = 'animated faster fadeOutUp';

    /* ----- Show Toast ----------------------------------------------------------------------------------------------- */
    function show(msg, type) {
        if (!msg) {
            return false;
        }
        var
            $self = $('div.main:visible').not('.system, .backdrop').last().find('header .toast'),
            $status;
        switch (type) {
            case 'success':
                $status = $('<i class="status fas fa-check"></i>');
                break;
            case 'error':
                $status = $('<i class="status fas fa-exclamation"></i>');
                break;
            default:
                $status = $('');
        }
        $self.find('.status').remove();
        $status
            .addClass('flex-min-content')
            .insertBefore($self.find('.msg'));
        $self
            .show()
            .removeClass(classListHide).addClass(classListShow)
            .find('.msg').text(msg);
        clearTimeout(timer);
        timer = setTimeout(hideAll, 5000);
    }

    /* ----- Hide all Toast ------------------------------------------------------------------------------------------- */
    function hideAll() {
        $('div.main header .toast').filter('.active').each(hide);
    }

    /* ----- Hide Toast ----------------------------------------------------------------------------------------------- */
    function hide() {
        var $this = $(this);
        $this
            .removeClass(classListShow)
            .addClass(classListHide)
            .on('animationend animationcancel', function (e) {
                var target = e.target || e.originalEvent.target;
                if (target === this) {
                    $this
                        .off('animationend animationcancel')
                        .removeClass(classListHide)
                        .hide();
                }
            });
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(document).on('click', 'header .toast .close', hideAll);
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        show: show
    };
})();

var scwowzaplaying = false;

var Soundchannels = (function () {

    var $templateSoundchannelsItem;

    var sdpConstraints = {
        OfferToReceiveAudio : true,
        OfferToReceiveVideo : true
    };

    var mediaConstraints = {
        video : false,
        audio : false
    };

    var WebRTCState = {
        settings: {
        }
    };

    let statePrefix = 'play';

    var WowzaWebRTCPlay = new wowzawebrtc();

    var streamId;
    var _selectedId;
    var toTurnoff;
    var webRTCAdaptor;
    var webRTCInitialized = false;
    var scplayer;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateSoundchannelsItem = $('template#tplSoundchannelsItem').remove().contents();
        BuildSoundchannels();
        scplayer = videojs('wowzaSoundchannel');
    }

    function sort() {
        AppData.translator.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function BuildSoundchannels() {
        var $fragmentSoundchannels = $(document.createDocumentFragment()),
            $clone = $templateSoundchannelsItem.clone();

        // $clone.attr("data-id", "turnoffSoundchannels");
        // $clone.find('.data-name').attr("data-lang", "soundchannels-turnoff").text(Language.getItem("soundchannels-turnoff"));
        // $clone.appendTo($fragmentSoundchannels);

        sort();

        $.each(AppData.translator, function (i, el) {
            if (el.visible != undefined && el.visible) {
                $clone = $templateSoundchannelsItem.clone();

                $clone.attr("data-id", el.id);
                $clone.find('.data-name').text(el.name[localState.lang]).attr("data-id", el.id).attr("data-server_url", el.server_url);
                /*if (localState.currentBroadcast === el.id) {
                    $clone.addClass('color-primary');
                }*/
                $clone.appendTo($fragmentSoundchannels);
            }
        });
        $('#soundchannels #soundchannelsList').html($fragmentSoundchannels);
    }

    $(document).on('click', '#soundchannelsList .item', function () {
        let id = $(this).attr("data-id");
        playSoundChannel(id);
    });

    function playSoundChannel(selectedId = '') {
        var that = $(this),
            isSelectedId = false;

        var translator_item = AppData.translator.find(function (item){
            return item.id == selectedId;
        });

        if (translator_item) {
            if (translator_item.wowza_stream != "" && translator_item.wowza_stream != undefined && translator_item.use_hls.indexOf(AppData.profile.group) > -1) {
                if (scwowzaplaying != selectedId) {

                    $("#soundchannelsList .item").removeClass("publishing");
                    $("#default_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
                    $("#minimal_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');

                    videojs('my-video').muted(true);

                    scwowzaplaying = selectedId;
                    scplayer.reset();
                    setTimeout(function (){
                        scplayer.src(translator_item.wowza_stream);
                        scplayer.play();
                        scplayer.muted(false);
                    }, 100);
                    writeDbLog("start soundchannel", String(translator_item.id), "user_logs", "translator");

                    $("#soundchannels div[data-id='"+selectedId+"']").addClass("publishing");
                    $("#default_nav").find('.open-main.soundchannels[data-targetid="'+selectedId+'"]').addClass('greensoundchannelsbutton');
                    $("#minimal_nav").find('.open-main.soundchannels[data-targetid="'+selectedId+'"]').addClass('greensoundchannelsbutton');

                    Layout.hide("soundchannels");

                } else {
                    scwowzaplaying = false;
                    scplayer.paused();
                    scplayer.reset();
                    scplayer.muted(true);

                    videojs('my-video').muted(false);

                    $("#soundchannelsList .item").removeClass("publishing");
                    $("#default_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
                    $("#minimal_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
                }

                return
            }
        }

        _selectedId = selectedId;

        if (selectedId == toTurnoff) {
            $("#soundchannelsList .item").removeClass("publishing");
            stopWebRTC();
            streamId = undefined;
            toTurnoff = undefined;
            return;
        }

        if (selectedId != "turnoffTranslation") {
            toTurnoff = selectedId;
        }

        if (streamId != undefined && webRTCInitialized) {
            $("#soundchannelsList .item").removeClass("publishing");
            stopWebRTC();
            streamId = undefined;
        }

        if (selectedId == "turnoffSoundchannels") {
            $("#soundchannelsList .item").removeClass("publishing");
            if (streamId != undefined && webRTCInitialized) {
                stopWebRTC();
                streamId = undefined;
            }
            return;
        }

        var data = AppData.translator.find(function(el) {
            return el.id == selectedId;
        });

        if (data) {

            WebRTCState = {
                settings: {
                    playSdpURL: data.signaling_url,
                    playApplicationName: data.application_name,
                    playStreamName: data.stream_name
                }
            };
            if (!webRTCInitialized) {
                init(WebRTCerrorHandler, onPlayPeerConnected, onPlayPeerConnectionStopped);
                //that.addClass("publishing");
                $("#soundchannelsList .item[data-id='"+selectedId+"']").addClass("publishing");
                $("#default_nav").find('.open-main.soundchannels[data-targetid="'+selectedId+'"]').addClass('greensoundchannelsbutton');
                $("#minimal_nav").find('.open-main.soundchannels[data-targetid="'+selectedId+'"]').addClass('greensoundchannelsbutton');
            } else {
                startWebRTC(WebRTCState.settings);
                //that.addClass("publishing");
                $("#soundchannelsList .item[data-id='"+selectedId+"']").addClass("publishing");
                $("#default_nav").find('.open-main.soundchannels[data-targetid="'+selectedId+'"]').addClass('greensoundchannelsbutton');
                $("#minimal_nav").find('.open-main.soundchannels[data-targetid="'+selectedId+'"]').addClass('greensoundchannelsbutton');
            }

            streamId = data.stream_name;

            $("#remoteSoundchannel").get(0).muted = false;
            $("#remoteSoundchannel").get(0).play();
        }
    }

    const init = (errorHandler,connected,stopped) => {
        WowzaWebRTCPlay.on({
            onError:errorHandler,
            onStateChanged: (state) => {
                console.log("state.connectionState", state.connectionState);
                if (state.connectionState === 'connected') {
                    console.log("connected in");
                    connected();
                } else if (state.connectionState === 'close') {
                    console.log("closed");
                } else {
                    stopped();
                    console.log("stopped in");
                }
            }
        });
        WowzaWebRTCPlay.set({
            videoElementPlay:document.getElementById('remoteSoundchannel') // _html5_api'),
        });

        console.log("init");

        webRTCInitialized = true;
        startWebRTC(WebRTCState.settings);
    }

    const getWebRTCState = () => {
        return WebRTCState;
    }

    const startWebRTC = (settings) => {
        console.log("start");
        toTurnoff = _selectedId;
        updateWebRTC(settings).then(() => {
            console.log("updated start");
            WowzaWebRTCPlay.play();
            var streamplayer = videojs('my-video');
            streamplayer.muted(true);

            Layout.hide("soundchannels");
        });
    }

    const stopWebRTC = () => {
        if (WowzaWebRTCPlay.wowzaPeerConnectionPlay != undefined) {
            WowzaWebRTCPlay.stop();

            $("#soundchannelsList .item").removeClass("publishing");
            $("#default_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
            $("#minimal_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
            console.log("play finished");
            streamId = undefined;
            toTurnoff = undefined;

            var streamplayer = videojs('my-video');
            streamplayer.muted(false);
        }
    }

    const updateWebRTC = (settings) => {
        WebRTCState.settings = settings;
        let sendSettings = {};
        for (let key in settings)
        {
            let sendKey = key.substring(statePrefix.length);
            sendKey = sendKey[0].toLowerCase() + sendKey.slice(1);
            sendSettings[sendKey] = settings[key];
        }
        return WowzaWebRTCPlay.set(sendSettings);
    }

    const onPlayPeerConnected = () => {
        WebRTCState.playing = true;
    }

    const onPlayPeerConnectionStopped = () => {
        WebRTCState.playing = false;
    }

    const WebRTCerrorHandler = (error) => {
        let message;
        if ( error.message ) {
            message = error.message;
        }
        else {
            message = error
        }
        console.log(message);

        if (message.indexOf("Waiting for stream") > -1) {
            startWebRTC(WebRTCState.settings);
        } else {
            $("#soundchannelsList .item").removeClass("publishing");
            streamId = undefined;
            toTurnoff = undefined;

            var streamplayer = videojs('my-video');
            streamplayer.muted(false);

            $("#default_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
            $("#minimal_nav").find('.open-main.soundchannels').removeClass('greensoundchannelsbutton');
            Modal.alert(Language.getItem("channel-not-available"));
        }
    };

    function showOnAir(id) {
        if (id) {
            var el = $('#soundchannelsList div[data-id="'+id+'"]');
            el.addClass("available");

            $("#default_nav").find('.open-main.soundchannels[data-targetid="'+id+'"]').addClass('available');
            $("#minimal_nav").find('.open-main.soundchannels[data-targetid="'+id+'"]').addClass('available');
        }
    }

    function streamFinished(id) {
        if (id) {
            var el = $('#soundchannelsList div[data-id="'+id+'"]');
            el.removeClass("available");

            $("#default_nav").find('.open-main.soundchannels[data-targetid="'+id+'"]').addClass('available');
            $("#minimal_nav").find('.open-main.soundchannels[data-targetid="'+id+'"]').addClass('available');

            if (id == _selectedId) {
                stopWebRTC();
            }
        }
    }

    function showDetails(id) {
        if (id) {
            if (localState.soundchannels != undefined) {
                if (id !== localState.soundchannels.itemIndex || localState.soundchannels.itemIndex === 0) {
                    localState.soundchannels.itemIndex = String(id);
                }
            } else {
                localState.soundchannels = { 'itemIndex': String(id)};
            }

            playSoundChannel(id);
        }
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getTranslator'
        }, function (data) {
            AppData.translator = data;
            BuildSoundchannels();
        });
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showOnAir: showOnAir,
        streamFinished: streamFinished,
        showDetails: showDetails,
        stopWebRTC: stopWebRTC
    }
})();

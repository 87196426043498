/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
var Presentation = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var
        playbackController,
        soundController,
        presentationClock,
        debounce = false,
        timeout;


    function Init() {
        /* ----- iSpring Connector ---------------------------------------------------------------------------------------- */
        // window.ispringPresentationConnector = {};
        // ispringPresentationConnector.register = function (player) {
        //     playbackController = player.view().playbackController();
        //     playbackController.gotoFirstSlide(true);
        //     presentationClock = playbackController.clock();
        //     soundController = player.view().soundController();
        //     soundController.mute(true);
        //     /* Start Presentation on Stop Evenet */
        //     presentationClock.stopEvent().addHandler(function (theClock) {
        //         var ts = theClock.timestamp();
        //         if (!ts.slideIndex() && !ts.stepIndex() && !ts.timeOffset()) {
        //             //window.console.log( "Clock has been stopped at slide:", ts.slideIndex(), "step:", ts.stepIndex(), "time offset:", ts.timeOffset() );
        //             setTimeout(function () {
        //                 playbackController.play();
        //             }, 0);
        //         }
        //     });
        //     /*if ( localState.presentationState ) {
        //      var s = localState.presentationState;
        //      playbackController.gotoTimestamp( s.slideIndex, s.stepIndex, s.timeOffset, true );
        //      }*/
        // };
    }

    /* ----- Control: Goto TimeStamp ---------------------------------------------------------------------------------- */
    function gotoTimestamp(data) {
        playbackController.gotoTimestamp(data.slideIndex, data.stepIndex, data.timeOffset, true);
        saveLocalState();
    }

    /* ----- Control: First Slide ------------------------------------------------------------------------------------- */
    function firstSlide() {
        //playbackController.gotoFirstSlide();
        //saveLocalState();

        $('#iSpringFrame_attendee').get(0).contentWindow.postMessage({action: "firstSlide"});
    }

    /* ----- Control: Next Slide -------------------------------------------------------------------------------------- */
    function nextSlide() {
        //playbackController.gotoNextStep();
        //trySyncVideo();
        //saveLocalState();

        $('#iSpringFrame_attendee').get(0).contentWindow.postMessage({action: "nextSlide"});
    }

    /* ----- Control: Prevous Slide ----------------------------------------------------------------------------------- */
    function prevSlide() {
        //playbackController.gotoPreviousSlide();
        //trySyncVideo();
        //saveLocalState();

        $('#iSpringFrame_attendee').get(0).contentWindow.postMessage({action: "prevSlide"});
    }

    function gotoSlide(slide) {
        $('#iSpringFrame_attendee').get(0).contentWindow.postMessage({action: "goToSlide", slide: slide});
    }

    /* ----- Control: Blank Screen Toggle ----------------------------------------------------------------------------- */
    function toggleBlank() {
        $('#iSpringFrame_attendee').contents().find('body').toggle();
    }

    /* ----- Control: iFrame Source ----------------------------------------------------------------------------------- */
    function source(src) {

        var presi = AppData.medias.find(function (item) {
            return item.id == src;
        });

        var path = "";

        if (presi != undefined && presi.files[localState.lang] != undefined) {
            path = presi.files[localState.lang].foldername + "/";
        }

        var
            $embed = $('#iSpringFrame_attendee'),
            $url = path + 'index.html';
        /* Change */
        if (src && $url !== $embed.attr('src')) {

            $embed.get(0).contentWindow.postMessage({action: "addSrc", url: $url});

            //$embed.attr('src', $url);
            //localState.presentation = src;
        }
        /* Remove*/
        if (!src) {
            setTimeout(function () {

                $embed.get(0).contentWindow.postMessage({action: "removeSrc"});

                //$embed.removeAttr('src');
                //delete localState.presentation;
                //delete localState.presentationState;
            }, 1000);
        }
    }

    /* ----- VideoPlayer Time Code -------------------------------------------------------------------------------------- */
    function trySyncVideo() {
        $('#iSpringFrame').contents().find('video').each(function () {
            var self = this;
            self.ontimeupdate = function () {
                if (!debounce) {
                    debounce = true;
                    Socket.Send('Presentation.syncVideo', self.currentTime);
                    timeout = setTimeout(function () {
                        debounce = false;
                    }, 1000 / 60 * 45);
                }
            };
        });
    }

    /* ----- VideoPlayer Time Code ------------------------------------------------------------------------------------ */
    function syncVideo(time) {
        debounce = true;
        clearTimeout(timeout);
        var
            video = $('#iSpringFrame').contents().find('video').get(0),
            maxDIff = .2,
            offsetDiff = .3,
            refTime = time,
            thisTime = video.currentTime;
        if (refTime > 0 && video.currentTime > 0) {
            if (thisTime + maxDIff < refTime) {
                video.currentTime += parseFloat((refTime - thisTime + offsetDiff).toFixed(1));
            }
            if (thisTime - maxDIff > refTime) {
                video.currentTime -= parseFloat((thisTime - refTime - offsetDiff).toFixed(1));
            }
        }
    }

    /* ----- Save Last State ------------------------------------------------------------------------------------------ */
    function saveLocalState() {
        localState.presentationState = {
            slideIndex: presentationClock.timestamp().slideIndex(),
            stepIndex: presentationClock.timestamp().stepIndex(),
            timeOffset: presentationClock.timestamp().timeOffset()
        };
    }

    function show() {
        if (!$(".speaker_presentation").is(":visible") && !$(".presentation").is(":visible")) {

            let currentSlide = AppData.presentations[localState.currentChannel+"_state"] ? AppData.presentations[localState.currentChannel+"_state"] : 0;

            $(".presentation").fadeIn(400, function () {
                //$('#iSpringFrame_attendee').get(0).contentWindow.postMessage({action: "goToSlide", slide: currentSlide});
            });
        }
    }

    function hide() {
        $(".presentation").fadeOut(400);
    }

    /* ----- Process on Document Ready ---------------------------------------------------------------------------------- */
    $(function () {
        /*if (localState.presentation) {
            Presentation.source(localState.presentation);
        }*/
    });
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        gotoTimestamp: gotoTimestamp,
        gotoSlide: gotoSlide,
        firstSlide: firstSlide,
        nextSlide: nextSlide,
        prevSlide: prevSlide,
        toggleBlank: toggleBlank,
        source: source,
        syncVideo: syncVideo,
        show: show,
        hide: hide,
    };
})();
